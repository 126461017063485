import * as yup from 'yup';
import googleLibphone from 'google-libphonenumber';
import moment from 'moment';
import 'moment/locale/pt-br';
import { formatDateOnly } from '../../../../utils/formatDate'
import { cpf } from 'cpf-cnpj-validator';

moment.locale('pt-br');

const issueMinDate = new Date(new Date().getTime() - (86400000 * 3650))
const minDataBirthday = new Date(new Date().getTime() - (86400000 * ((18 * 365) + 4)))

const phoneUtil = googleLibphone.PhoneNumberUtil.getInstance();

const validateNumber = (number: string | undefined) => {
  try {
    const parserNumber = phoneUtil.parseAndKeepRawInput(number as string, 'BR');
    const validationPhone = phoneUtil.isValidNumberForRegion(
      parserNumber,
      'BR',
    );

    if (!validationPhone) {
      return false;
    }

    return parserNumber;
  } catch (err) {
    return false;
  }
};

const validateCellphone = (number: string | undefined) => {
  const validNumber = validateNumber(number);

  if (!validNumber) {
    return false;
  }

  const typeNumber = phoneUtil.getNumberType(validNumber);

  if (typeNumber !== 1) {
    return false;
  }

  return true;
};


const today = moment().format(moment.HTML5_FMT.DATE);

const schemaClient = yup.object().shape({





  pixKeyType: yup.object().shape({
    label: yup.string(),
    name: yup
      .mixed()
      .oneOf(
        [
          'cnpj',
          'email',
          'phone',
          'cpf',
          'key_random'
        ])
  }).required('Informe o tipo de chave PIX.'),

  pixKey: yup.string().required('Informe a chave PIX')
});

const step1 = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(
      4,
      'Digite seu nome completo (sem abreviações);',
    )
    .required('Digite seu nome completo (sem abreviações);'),

  CPF: yup
    .string()
    .test('validateCPF', 'Informe seu CPF corretamente;', (value) => cpf.isValid(value as string))
    .required('Informe seu CPF corretamente;'),

  birthday: yup
    .date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .max(
      minDataBirthday,
      `Data de nascimento deve ser igual ou inferior a ${formatDateOnly(minDataBirthday)
      };`,
    )
    .required('Informe sua data de nascimento corretamente;'),

  // birthCity: yup.string().trim().required('Selecione a sua cidade de nascimento;'),

  motherName: yup
    .string()
    .trim()
  // .min(12, "Digite o nome completo da sua mãe;")
  // .required('Digite o nome completo da sua mãe;'),
})

const step2 = yup.object().shape({
  name: yup
    .string().when('sintegraError', {
      is: true,
      then: yup
        .string().trim()
        .min(
          4,
          'Digite seu nome completo (sem abreviações);',
        )
        .required('Digite seu nome completo (sem abreviações);'),
    }),

  gender: yup
    .mixed().when('sintegraError', {
      is: true,
      then: yup.mixed().oneOf(['F', 'M', 'O'], 'Selecione o seu gênero;')
        .required('Selecione o seu gênero;'),
    }),
  email: yup
    .string()
    .email('Informe um email válido. Ex.: exemplo@exemplo.com;')
    .required('Informe seu melhor e-mail;'),

  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe seu whatsapp;',
      validateCellphone,
    )
    .required('Informe seu whatsapp;'),



  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Digite seu atual CEP;')
    .required('Digite seu atual CEP;'),

  state: yup
    .mixed()
    .oneOf(
      [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO',
      ],
      'Selecione seu atual Estado;',
    )
    .required('Selecione seu atual Estado;'),

  city: yup.string().trim().required('Selecione sua atual cidade;'),

  district: yup
    .string()
    .trim()
    .min(4, 'Digite seu atual bairro;')
    .required('Digite seu atual bairro;'),
  address: yup
    .string()
    .trim()
    .min(4, 'Digite seu atual endereço completo;')
    .required('Digite seu atual endereço completo;'),

  addressNumber: yup.string().trim().required('Digite o número da sua residência;'),
  rg: yup
    .string()
    .trim()
    .max(20, 'RG ou CNH deve ter no máximo 20 caracteres.')
    .required('Digite seu RG ou CNH corretamente;'),

  issuingAgency: yup.string().trim().required('Informe o órgão expedidor do seu documento;'),

  issueDate: yup
    .date()
    .nullable()
    .min(
      issueMinDate,
      `Data de emissão do documento deve ser igual ou superior a ${formatDateOnly(issueMinDate)
      };`,
    )
    .max(
      today,
      `Data de emissão do documento deve ser igual ou inferior a ${formatDateOnly(
        new Date(today),
      )};`,
    )
    .required('Informe a data que seu documento foi expedido;'),
  recaptchaToken: yup.string().nullable().required('Clique no re-captcha e selecione as figuras solicitadas;')
})

const step3 = yup.object().shape({
  account_type: yup
    .mixed()
    .oneOf(['CONTA_CORRENTE', 'CONTA_POUPANCA', 'CONTA_FACIL'], 'Selecione o tipo de conta;')
    .required('Selecione o tipo de conta;'),
  bank: yup.string().required('Selecione o seu banco;'),
  agency_number: yup.string().max(4, 'Agência deve ter no máximo 4 caracteres;').required('Informe sua agência;'),
  account: yup.string().min(4, 'Informe a conta bancária corretamente.').max(14, 'Conta e dígito deve ter no máximo 14 caracteres.').required('Digite a conta bancária e dígito (tudo junto).'),
})

const step4 = yup.object().shape({
  // birthState: yup
  //   .mixed()
  //   .oneOf(
  //     [
  //       'AC',
  //       'AL',
  //       'AP',
  //       'AM',
  //       'BA',
  //       'CE',
  //       'DF',
  //       'ES',
  //       'GO',
  //       'MA',
  //       'MT',
  //       'MS',
  //       'MG',
  //       'PA',
  //       'PB',
  //       'PR',
  //       'PE',
  //       'PI',
  //       'RJ',
  //       'RN',
  //       'RS',
  //       'RO',
  //       'RR',
  //       'SC',
  //       'SP',
  //       'SE',
  //       'TO',
  //     ],
  //     'Selecione o seu Estado de nascimento;',
  //   )
  //   .required('Selecione o seu Estado de nascimento;'),

  gender: yup
    .mixed()
    .oneOf(['F', 'M', 'O'], 'Selecione o seu gênero;')
    .required('Selecione o seu gênero;'),
  rg: yup
    .string()
    .trim()
    .max(20, 'RG ou CNH deve ter no máximo 20 caracteres.')
    .required('Digite seu RG ou CNH corretamente;'),

  issuingAgency: yup.string().trim().required('Informe o órgão expedidor do seu documento;'),

  issueDate: yup
    .date()
    .nullable()
    .min(
      issueMinDate,
      `Data de emissão do documento deve ser igual ou superior a ${formatDateOnly(issueMinDate)
      };`,
    )
    .max(
      today,
      `Data de emissão do documento deve ser igual ou inferior a ${formatDateOnly(
        new Date(today),
      )};`,
    )
    .required('Informe a data que seu documento foi expedido;'),

})

export { step1, step2, step3, step4 };