import { useContext, useEffect, useRef, useState } from "react"
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material"
import { ErrorOutline } from '@mui/icons-material';

import Axios, { CancelTokenSource } from 'axios'
import { useSnackbar } from 'notistack'
import './styles.css'
import { StepContext } from "../../context"
import { formatCurrency } from "../../../utils/formatCurrency"
import { LinkInterface, PriceInterface, ProposalProps, SessionVerifyStepsProps } from '../../@interfaces'

import womanImage from '../../../assets/woman.png'
import banner from '../../../assets/imagem empreste agora 02.png'
import logoTC from '../../../assets/logo_ayude.png'
import logoSSL from '../../../assets/LOGO-CERTIFICADO.png'
import congratulationsImage from '../../../assets/congratulations.png'
import termoAceiteUso from '../../../assets/TERMO DE ACEITE, DE USO E CESSÃO -  NACIONAL PAGAMENTOS.pdf'
import Thumbnail from '../../../assets/thumb.jpg'
import WarningIcon from '../../../assets/warning.png'
import { StepIndicator } from "../../stepIndicator"
import { OperationError } from "../../operationError"
import WhatsAppIcon from '../../../assets/whatsapp.svg'
import { transformValue } from "../../../utils/transformValue"
import { useParams } from "react-router-dom"

import { InputMaskComponent } from "../../form/inputMask"
import { formatinputs } from "../../../utils/formatCpf"
import { awaitTime } from "../../../utils/timing"
import { Tarja } from "../../tarja"
import { formatDateOnly, toAAAMMDD, toAAAMMDDString } from "../../../utils/formatDate"
import { stepVerify } from "./validator"
import ReactPlayer from 'react-player'
import whatsAppIcon from '../../../assets/whatsApp.png'
import { InputComponent } from "../../form/input";
import { SelectComponent } from "../../form/select";


export default function Verify() {
  const { enqueueSnackbar } = useSnackbar()
  const { linkID: linkTableId, userId } = useParams()
  const { linkId,
    setStep,
    step,
    setKeepGoing,
    setProposal,
    cardLimit,
    setCardLimit,
    valueProposal,
    keepGoing,
    setDataLink,
    dataLink,
    setValueProposal,
    setProgressIndicator,
    progressIndicator,
    verifySteps,
    setVerifySteps,
    proposal,
    postalCode,
    setPostalCode,
    setCPF,
    CPF,
    loading,
    setLoading,
    defaultUserID,
    setLinkId,
    dataPrice,
    setDataPrice,
    saleTable,
    setSaleTable,
    preApproved,
    setPreApproved,
    setRegisterSteps,
    birthday,
    setBirthday,
    handleDates,
    setName,
    setGender,
    sintegraError,
    setSintegraError,
    cellphone,
    email,
    setCellphone,
    setEmail,
    HandleSteps
  } = useContext(StepContext)

  const [selected, setselected] = useState<PriceInterface | null>(null)
  const [otherValue, setOtherValue] = useState<boolean>(false)
  const [cardLimitIsHigher, setCardLimitIsHigher] = useState<boolean>(false)
  const [openDialogError, setOpenDialogError] = useState<boolean>(false)
  const [openDefaultAlert, setOpenDefaultAlert] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<string>('')
  const [redonlyAlert, setOpenRedonlyAlert] = useState<boolean>(false)
  const [getUf, setGetUf] = useState<boolean>(false)
  const [postalCodeAlert, setPostalCodeAlert] = useState<boolean>(false)
  const [cpfValid, setCpfValid] = useState<boolean>(false)
  const [cpfAlert, setCpfAlert] = useState<boolean>(false)
  const [valueFor18, setValueFor18] = useState<number>(0)
  const [valueFor24, setValueFor24] = useState<number>(0)
  const [openSummary, setOpenSummary] = useState<boolean>(false)
  const minValue = 300
  const [nextDate, setNextDate] = useState<string | null>(null)
  const [openFormError, setOpenFormError] = useState<boolean>(false)
  const [errorList, setErrorList] = useState<{ message: string; path: string }[]>([])
  const [errorSintegra, setErrorSintegra] = useState(false)
  const [limitForMonth, setLimitForMonth] = useState(false)
  const [showPreApproved, setShowPreApproved] = useState(false)
  const [showParcels, setShowParcels] = useState(false)
  const [showLimit, setShowLimit] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [disabledCardPreAproved, setDisabledCardPreAproved] = useState<boolean>(false)
  const [openPlayer, setOpenPlayer] = useState(false)
  const [disabledButtons, setDisabledButtons] = useState(false)
  const [openDialogSameValue, setOpenDialogSameValue] = useState(false)
  const [openDialogParcels, setOpenDialogParcels] = useState(false)
  const [openAlertValueInvalid, setOpenAlertValueInvalid] = useState(false)
  const [openAlertDeath, setOpenAlertDeath] = useState(false)
  const [availableAmount, setAvailableAmount] = useState(0)
  const [openModalPendingProposal, setOpenModalPendingProposal] = useState(false)
  const [pendingProposalLink, setPendingProposalLink] = useState("")
  const [contactReason, setContactReason] = useState("")

  const nextStep = () => {
    var cardInfo: ProposalProps = {}
    cardInfo.credit_card_limit = transformValue(cardLimit)
    cardInfo.parcels = selected?.parcels ?? 12
    cardInfo.net_amount = transformValue(valueProposal)

    setProposal(cardInfo)

    setStep(step + 1)
    setProgressIndicator(progressIndicator + 1)
    setRegisterSteps(2)

    const sessionVerify: SessionVerifyStepsProps = {
      cardLimitIsHigher,
      cpfAlert,
      cpfValid,
      getUf,
      messageError,
      openDefaultAlert,
      openDialogError,
      otherValue,
      postalCodeAlert,
      preApproved,
      redonlyAlert,
      selected,
      showForm,
      showLimit,
      showParcels,
      showPreApproved
    }

    sessionStorage.setItem('tc-sessionVerify', JSON.stringify(sessionVerify))
  }

  useEffect(() => {
    (async () => {
      await awaitTime(0.5)
      sessionStorage.setItem('tc-disabledCardPreAproved', JSON.stringify(disabledCardPreAproved))
    })()
  }, [disabledCardPreAproved])

  useEffect(() => {
    (async () => {
      await awaitTime(0.5)
      sessionStorage.setItem('tc-showForm', JSON.stringify(showForm))
    })()
  }, [showForm])

  useEffect(() => {
    (async () => {
      await awaitTime(0.5)
      sessionStorage.setItem('tc-showLimit', JSON.stringify(showLimit))
    })()
  }, [showLimit])

  useEffect(() => {
    (async () => {
      await awaitTime(0.5)
      sessionStorage.setItem('tc-showParcels', JSON.stringify(showParcels))
    })()
  }, [showParcels])

  useEffect(() => {
    (async () => {
      await awaitTime(0.5)
      sessionStorage.setItem('tc-showPreApproved', JSON.stringify(showPreApproved))
    })()
  }, [showPreApproved])

  useEffect(() => {
    (async () => {
      await awaitTime(0.5)
      sessionStorage.setItem('tc-availableAmount', JSON.stringify(availableAmount))
    })()
  }, [availableAmount])



  useEffect(() => {
    setCardLimitIsHigher(Number(cardLimit.replace('.', '').replace(',', '').replace(/\D/g, '')) > 0)
  }, [cardLimit])

  useEffect(() => {
    if (userId || (!userId && !linkTableId)) {
      setGetUf(true)
    }

    const sessionVerify = sessionStorage.getItem('tc-sessionVerify')
    if (sessionVerify) {
      setLoading(true)
      const data: SessionVerifyStepsProps = JSON.parse(sessionVerify)
      setCardLimitIsHigher(data.cardLimitIsHigher)
      setCpfAlert(data.cpfAlert)
      setCpfValid(data.cpfValid)
      setGetUf(data.getUf)
      setMessageError(data.messageError)
      setOpenDefaultAlert(data.openDefaultAlert)
      setOpenDialogError(data.openDialogError)
      setOtherValue(data.otherValue)
      setPostalCodeAlert(data.postalCodeAlert)
      setPreApproved(data.preApproved as number)
      setOpenRedonlyAlert(data.redonlyAlert)
      setLoading(false)
      setShowForm(data.showForm)
      setShowLimit(data.showLimit)
      setShowParcels(data.showParcels)
      setShowPreApproved(data.showPreApproved)
    }

    const sessionDisabledPreAproved = sessionStorage.getItem('tc-disabledCardPreAproved')
    if (sessionDisabledPreAproved) {
      setDisabledCardPreAproved(sessionDisabledPreAproved === "true" ? true : false)
    }
    const sessionshowLimit = sessionStorage.getItem('tc-showLimit')
    if (sessionshowLimit) {
      setShowLimit(sessionshowLimit === "true" ? true : false)
    }
    const sessionShowForm = sessionStorage.getItem('tc-showForm')
    if (sessionShowForm) {
      setShowForm(sessionShowForm === "true" ? true : false)
    }
    const sessionshowParcels = sessionStorage.getItem('tc-showParcels')
    if (sessionshowParcels) {
      setShowParcels(sessionshowParcels === "true" ? true : false)
    }
    const sessionPreApproved = sessionStorage.getItem('tc-PreApproved')
    if (sessionPreApproved) {
      setShowPreApproved(sessionPreApproved === "true" ? true : false)
    }
    const sessionAvailableAmount = sessionStorage.getItem('tc-availableAmount')
    if (sessionAvailableAmount) {
      setAvailableAmount(Number(sessionAvailableAmount))
    }

  }, [])

  useEffect(() => {
    (async () => {
      if (verifySteps === 5 && otherValue === true) {
        await awaitTime(0.4)
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        })
      }
    })()
  }, [verifySteps])

  const resetProposal = () => {
    sessionStorage.clear()
    /* @ts-ignore */
    location.reload(true) // eslint-disable-line
  }

  const sendToShow = async (point: string) => {
    await awaitTime(0.3)
    switch (point) {
      case "parcels":
        const parcelElement = document.getElementById('parcels')
        /*@ts-ignore*/
        if (parcelElement) {
          parcelElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;
      case "preApproved":
        const preApprovedElement = document.getElementById('preApproved')
        /*@ts-ignore*/
        if (preApprovedElement) {
          preApprovedElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;

      case "limit":
        const limitElement = document.getElementById('limit')
        /*@ts-ignore*/
        if (limitElement) {
          limitElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;
      case "buttonContinue":
        const buttonContinueElement = document.getElementById('buttonContinue')
        /*@ts-ignore*/
        if (buttonContinueElement) {
          buttonContinueElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;
      default:
        const element = document.getElementById('otherValue')
        /*@ts-ignore*/
        if (element) {
          element.scrollIntoView({
            block: "center",
            behavior: "smooth"
          });
        }
    }
  }


  async function getCep() {
    if (formatinputs.toString(postalCode).length === 8) {
      setLoading(true)
      const formatCep = formatinputs.toString(postalCode)
      Axios.get(`https://viacep.com.br/ws/${formatCep}/json/`).then(({ data }) => {
        if (data.erro) {
          setPostalCodeAlert(true)
        } else {
          const state = data.uf
          getLinkByUF(state)
        }
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setPostalCodeAlert(true)
    }
  }

  const getLinkByUF = async (state: string) => {
    setLoading(true)
    await Axios.post(`${process.env.REACT_APP_ONLINE_API}/links/uf`, {
      userId: defaultUserID,
      uf: state
    }, {
    }).then(({ data }) => {
      setLinkId(data.id)
      setShowLimit(true)
      setShowForm(false)
      sendToShow("limit")
      // setVerifySteps(Number(verifySteps) + 1)
      // setProgressIndicator(progressIndicator + 1)
    }).catch(() => {
      enqueueSnackbar("Falha ao buscar cep", {
        variant: 'error'
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  async function getDataLink() {
    console.log(transformValue(cardLimit))
    const isInvalidValue = transformValue(cardLimit).toString().split('.').length === 1
    if (isInvalidValue) {
      setOpenAlertValueInvalid(true)
      return
    }
    setLoading(true)
    Axios.get(`${process.env.REACT_APP_ONLINE_API}/links/${linkId}`).then(async ({ data }) => {
      if (Number(data.result[0].sale_table.split("on-")[1]) >= 15) {
        enqueueSnackbar(<p>Link indisponível. <br /> Solicite um novo link a seu agente bancário.</p>, {
          variant: 'warning',
        })
        return
      }
      setDataLink(data.result)
      setSaleTable(data.result[0].sale_table)
      let maxValue = 5000
      const lowestRate: LinkInterface = data?.result?.reduce((prev: LinkInterface, current: LinkInterface) => {
        return prev.parcels < current.parcels ? prev : current
      })

      const higherRate: LinkInterface = data?.result?.reduce((prev: LinkInterface, current: LinkInterface) => {
        return prev.parcels > current.parcels ? prev : current
      })
      const valuePreApproved = (transformValue(cardLimit) * 0.97) / (1 + (higherRate.fees_tax))
      if (valuePreApproved < minValue) {
        setPreApproved(minValue * (higherRate.fees_tax + higherRate.prime_tax) + minValue)
        setVerifySteps('insufficientLimit')
      } else {
        if (valuePreApproved < maxValue) {
          const redonlyValue = valuePreApproved % 10
          if (redonlyValue === 0) {
            if (valuePreApproved > availableAmount) {
              setPreApproved(availableAmount)
            } else {
              setPreApproved(valuePreApproved)
            }
          } else {
            if ((valuePreApproved - redonlyValue) > availableAmount) {
              setPreApproved(availableAmount)
            } else {
              setPreApproved(valuePreApproved - redonlyValue)
            }
          }
        } else {
          if (maxValue > availableAmount) {
            setPreApproved(availableAmount)
          } else {
            setPreApproved(maxValue)
          }
        }
      }
      setShowPreApproved(true)
      sendToShow("preApproved")

    }).catch(() => {
      enqueueSnackbar(<p>Link indisponível. <br /> Solicite um novo link a seu agente bancário.</p>, {
        variant: 'warning',
      })
    }).finally(() => {
      setLoading(false)
    })

  }

  const moneyMask = (value: string) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )

    return 'R$ ' + result
  }

  const exit = () => {
    setVerifySteps(1)
    setCardLimit('0')
    setProposal({})
    setValueProposal('0')
    setProgressIndicator(1)
    sessionStorage.clear()
    setShowForm(true)
    setShowLimit(false)
    setShowParcels(false)
    setShowPreApproved(false)
  }

  const handleCPF = () => {

    if (showForm === false) {
      sendToShow("limit")
      return
    }
    setLoading(true)
    stepVerify.validate({
      CPF,
      birthday: formatinputs.toString(birthday).length === 8 && toAAAMMDD(birthday) as string | Date !== 'Invalid Date' ? toAAAMMDD(birthday) : null,
      cellphone,
      CEP: postalCode.replace('.', ''),
      email,
      contactReason
    }, {
      abortEarly: false
    }).then(() => {
      Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals/consult-cpf/${formatinputs.toString(CPF)}`).then(({ data }) => {
        if (data.result.continue === true) {
          setAvailableAmount(data.result.availableAmount)
          consultCPF()
        } else {
          if (data.result.limit) {
            setLimitForMonth(true)
          } else if (data.result.pendingProposal) {
            if (data.result.proposalDetails?.link) {
              setPendingProposalLink(data.result.proposalDetails.link)
            }
            setOpenModalPendingProposal(true)
          } else {
            setVerifySteps('cpfBlocked')
            setNextDate(addDays(new Date(data.result.date), 11))
          }
        }
      })
    }).catch(async (err: any) => {
      console.log(err)
      const array: { message: string; path: string }[] = []
      await err.inner.forEach((element: any) => {

        if (array.filter(e => e.path === element.path).length === 0) {
          array.push({ message: element.message, path: element.path })
        }
      })
      setErrorList(array)
      setOpenFormError(true)
    }).finally(() => {
      setLoading(false)
    })

  }

  const consultCPF = () => {
    setLoading(true)
    const CancelToken = Axios.CancelToken;
    const cancelTokenSource = CancelToken.source();
    cancelRequest(cancelTokenSource, 60)
    Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals/consult-localize`, {
      params: {
        cpf: formatinputs.toString(CPF),
        birthday: birthday
      }
    }).then(({ data }) => {
      if (data.result.error) {
        if (data.result.point === "death") {
          setOpenAlertDeath(true)
          return
        } else if (data.result.point === "birthday") {
          setErrorList([{
            message: 'Data de nascimento incorreta.',
            path: 'sintegra'
          }])
          setOpenFormError(true)
          return
        }
      } else {
        //aqui
        setName(data.result.name)
        setGender(data.result?.gender ?? "M")
        setShowLimit(true)
        setShowForm(false)
        sendToShow("limit")
        saveRemarketing({
          gender: data.result.gender,
          name: data.result.name
        })
      }
    }).catch((err) => {
      if (err.response?.data?.message?.resposta === "CPF inválido.") {
        setErrorList([{
          message: 'CPF inválido.',
          path: 'sintegra'
        }])
        setOpenFormError(true)
        return
      }
      setSintegraError(true)
      if (getUf) {
        getCep()
      } else {
        setShowLimit(true)
        setShowForm(false)
        sendToShow("limit")
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const saveRemarketing = async (data: {
    name: string,
    gender: string,
  }) => {
    let body: {
      cpf?: string,
      name: string,
      birthday?: string,
      gender: string,
      whatsapp?: string,
      email?: string,
      address?: {
        district: string,
        cep: string,
        city: string,
        state: string,
        street: string,
        complement?: string
      },
      link_id?: string,
      contact_reason: string
    } = {
      name: data.name,
      gender: data.gender,
      contact_reason: contactReason
    }

    body.cpf = formatinputs.toString(CPF)
    body.birthday = toAAAMMDDString(birthday)
    body.email = email
    body.whatsapp = formatinputs.toString(cellphone)
    body.link_id = linkId


    await Axios.get(`https://viacep.com.br/ws/${formatinputs.toString(postalCode)}/json/`).then(({ data }) => {
      if (data.erro) {
        setPostalCodeAlert(true)
      } else {
        body.address = {
          cep: formatinputs.toString(postalCode),
          city: data.localidade,
          district: data.bairro,
          state: data.uf,
          street: data.logradouro,
          complement: data.complemento
        }
      }
    })

    console.log(body)

    await Axios.post(`${process.env.REACT_APP_ONLINE_API}/remarketing`, body)

  }

  const getPrices = async (value: number | string) => {
    setLoading(true)
    const verifyValue = await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals/consult-cpf/${formatinputs.toString(CPF)}?verifyValue=true&amount=${typeof value === 'string' ? transformValue(value) : value}`).then(({ data }) => data)
    if (verifyValue.result.continue === false && verifyValue.result.amount) {
      setOpenDialogSameValue(true)
      setLoading(false)
      return
    }
    const dataPayload = {
      amount: typeof value === 'string' ? transformValue(value) : value,
      sale_table: saleTable
    }
    const CancelToken = Axios.CancelToken;
    const cancelTokenSource = CancelToken.source();
    cancelRequest(cancelTokenSource)
    await Axios.post(`${process.env.REACT_APP_ONLINE_API}/proposals/price`, dataPayload, {
      cancelToken: cancelTokenSource.token
    }).then(({ data }) => {
      setDataPrice(data)
      setValueProposal(formatCurrency(dataPayload.amount))
      const filter = data.find((i: PriceInterface) => i.parcels === 12) as PriceInterface
      if (filter) {
        const value18x = filter.total + 3
        setValueFor18(value18x)

        const value24x = value18x + 3
        setValueFor24(value24x)
      }
      setShowParcels(true)
      sendToShow("parcels")
      setselected(null)
    }).catch((e) => {
      if (Axios.isCancel(e)) {
        return getPrices(value)
      }
    }).finally(() => setLoading(false))
  }

  const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + days)
    return date.toISOString()
  }

  async function cancelRequest(cancelToken: CancelTokenSource, time: number = 3) {
    await awaitTime(time)
    cancelToken.cancel('')
  }

  const render = () => {
    switch (verifySteps) {

      case 1:
        return (
          <div className="divWellcome">
            <div className="containerImages">
              <img src={banner} alt="banner" className="banner" />
            </div>
            <div className="containerWellcome">
              <img src={logoTC} alt="logoAyude" />
            </div>
            <div className="containerDescription">
              <p style={{
                color: 'black'
              }}>Parcele em até 12x no seu cartão de crédito</p>
            </div>
            <div className="containerButtons">
              <button style={{ marginTop: '10px', height: '10rem' }} className="requestButton" onClick={() => {
                if (defaultUserID === '' && !linkId) {
                  enqueueSnackbar(<p>Link indisponível. <br /> Solicite um link a seu agente bancário.</p>, {
                    variant: 'warning',
                  })
                } else {
                  setProgressIndicator(1)
                  setVerifySteps(Number(verifySteps) + 1)
                  HandleSteps(0)
                  // setOpenPlayer(true)
                }
              }}>Solicitar empréstimo</button>
              <a href="https://ayude.com.br" target="_blank">
                Saiba mais
              </a>
              <Link
                href={termoAceiteUso}
                variant="subtitle2"
                target="_blank"
              >
                Termo de aceite e de uso
                <br />
                AYUDE
              </Link>
            </div>
            <img style={{ marginTop: '-25px' }} className="logoSSLVerify" src={logoSSL} alt="Logo SSL" />
            <Tarja />
          </div>

        )

      case 2:
        return (
          <div className="divCardLimit defaultClassForTarja">
            <StepIndicator />
            {!showLimit &&
              <>
                <p className="cpfRequired">Para dar início no empréstimo digite seus dados abaixo</p>
                <InputMaskComponent autoDisabled={!showForm} mask="999.999.999-99" label="Seu CPF" type="tel" placeholder="000.000.000-00" value={CPF} onChange={(e) => setCPF(e.target.value)} />
                <InputMaskComponent autoDisabled={!showForm} id="birthday" label="Sua data de nascimento" mask="99/99/9999" type="tel" placeholder="DD/MM/AAAA" value={birthday} onChange={(e) => handleDates(e, setBirthday, 'birthday')} />
                <InputMaskComponent autoDisabled={!showForm} mask="(99) 99999-9999" label="Seu WhatsApp" type='tel' placeholder="(DDD)" value={cellphone} onChange={(e) => setCellphone(e.target.value)} icon={<img src={whatsAppIcon} style={{
                  width: 65,
                  marginLeft: -20,
                  marginBottom: -2
                }} />} />
                <InputComponent autoDisabled={!showForm} label="Seu melhor e-mail" type="email" placeholder="exemplo@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                <InputMaskComponent autoDisabled={!showForm} mask="99.999-999" label="Seu CEP" type="tel" placeholder="Digite aqui..." value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                <SelectComponent
                  genderOptions={[
                    {
                      label: "Despesas básicas",
                      value: "0"
                    },
                    {
                      label: "Emergências",
                      value: "1"
                    },
                    {
                      label: "Educação",
                      value: "2"
                    },
                    {
                      label: "Consolidação de dívidas",
                      value: "3"
                    },
                    {
                      label: "Reformas",
                      value: "4"
                    },
                    {
                      label: "Lazer",
                      value: "5"
                    },
                    {
                      label: "Invetimento pessoal",
                      value: "6"
                    },
                    {
                      label: "Potencializar negócio",
                      value: "7"
                    },
                    {
                      label: "Outros",
                      value: "8"
                    },
                  ]}
                  label="Motivo da contratação"
                  value={contactReason}
                  onChange={(e) => setContactReason(e.target.value)}
                />
                {showForm && <button
                  className={(CPF && birthday && cellphone && postalCode && email) ? 'buttonCardActive toTop noTop' : 'buttonCardDisabled toTop noTop'}
                  onClick={handleCPF} >Continuar</button>
                }
              </>
            }

            {showLimit &&
              <>
                <p id="limit">Digite abaixo apenas o <br /> <strong style={{ color: 'red' }}>SALDO <u style={{ color: 'red' }}>DISPONÍVEL</u> <br /> PARA COMPRAS </strong><br /> em seu cartão de crédito
                </p>
                {/* <div className="textCard">
                  <p>Digite abaixo o SALDO DISPONÍVEL agora em seu cartão de crédito:</p>
                </div> */}
                <div className="valueCardLimit">
                  <input
                    inputMode="tel"
                    type="text"
                    value={moneyMask(cardLimit)}
                    onChange={(e) => {
                      if (transformValue(e.target.value) < 1000000) {
                        setCardLimit(e.target.value)
                      } else {
                        setCardLimit(moneyMask("99999999"))
                      }
                      if (showPreApproved) {
                        setShowPreApproved(false)
                      }
                    }}
                    style={{
                      color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                      backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                      borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                    }} />
                  <div />
                  <button
                    style={{ marginBottom: '40px' }}
                    className={cardLimitIsHigher && !showPreApproved ? 'buttonCardActive' : 'buttonCardDisabled'}
                    onClick={() => {
                      if (cardLimitIsHigher) {
                        getDataLink()
                      } else {
                        setOpenDialogError(true)
                      }
                    }}>Continuar</button>
                </div>
              </>
            }

            {/* show pre aproved */}

            {showPreApproved &&
              <>
                <div className="divCongratulations" id="preApproved">
                  <img src={congratulationsImage} alt="congratulationsImage" />
                  <p><strong>Receba</strong></p>
                </div>
                <div className="cardPreApproved">
                  {preApproved ? <p>{formatCurrency(preApproved as number)}</p> : <CircularProgress />}
                </div>
                <p className="informTimePayment">Em até 01 hora útil</p>

                <p>Este é o valor<br /> pré-aprovado para <br /> seu empréstimo!</p>

                <div className="containerButtonsProposal">
                  <button
                    onClick={() => {
                      getPrices(preApproved as number)
                      setValueProposal(formatCurrency(preApproved as number))
                      // setCheckoutSteps(4)
                    }}
                  >Quero o valor <br />pré-aprovado!</button>
                  <button
                    style={{ marginBottom: !otherValue ? '10rem' : '0px' }}
                    onClick={() => {
                      setOtherValue(true)
                      sendToShow("otherValue")
                    }}>Quero um valor menor</button>
                </div>
                {otherValue &&
                  <div className="otherValue" id="otherValue">
                    <p>Digite abaixo o valor desejado de até <strong>{formatCurrency(preApproved as number)}:</strong></p>
                    <p>Aviso: Digite apenas múltiplos de <strong>{formatCurrency(10)}</strong></p>

                    <div className="valueCardLimit">
                      <input
                        inputMode="tel"
                        type="text"
                        value={moneyMask(valueProposal)}
                        onChange={(e) => {
                          setValueProposal(e.target.value)
                        }}
                        style={{
                          color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                          backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                          borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                        }} />
                      <div />
                      <button
                        style={{ marginBottom: '10px' }}
                        className={transformValue(valueProposal) > 0 && transformValue(valueProposal) <= Number(preApproved) ? 'buttonCardActive defaultMarginBottom' : 'buttonCardDisabled defaultMarginBottom'}
                        onClick={() => {
                          if (transformValue(valueProposal) <= 0 || transformValue(valueProposal) > Number(preApproved)) {
                            setMessageError(transformValue(valueProposal) <= 0 ? 'Digite o valor desejado para continuar' : 'O valor do empréstimo precisa ser menor ou igual a')
                            setOpenDefaultAlert(true)
                          }
                          else {
                            if (transformValue(valueProposal) < minValue) {
                              setOpenDefaultAlert(true)
                            } else {
                              if (transformValue(valueProposal) % 10 !== 0) {
                                setOpenRedonlyAlert(true)
                              } else {
                                getPrices(valueProposal)
                              }
                            }
                          }
                        }} >Continuar</button>
                    </div>
                  </div>
                }
              </>}

            {/* show parcels */}

            {showParcels &&
              <>
                <p id="parcels">Escolha em <strong><u>quantas parcelas</u></strong> você quer pagar seu empréstimo de <strong><u>{valueProposal}</u></strong></p>

                <div className="listParcels">
                  {dataPrice.sort((a, b) => a.parcels - b.parcels).map((item: PriceInterface) => {
                    const lowestRate: PriceInterface = dataPrice.reduce((prev: PriceInterface, current: PriceInterface) => {
                      return prev.parcels < current.parcels ? prev : current
                    })
                    return (
                      <div className={lowestRate.parcels === item.parcels && !selected ? "parcel parcelPulse" : "parcel"} key={item.parcels}
                        onClick={() => {
                          if (selected) {
                            if (selected.parcels === item.parcels) {
                              setselected(null)
                            } else {
                              const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                              setselected(filtered as PriceInterface)
                              sendToShow("buttonContinue")
                            }
                          } else {
                            sendToShow("buttonContinue")
                            const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                            setselected(filtered as PriceInterface)
                          }
                        }}
                        style={{ backgroundColor: selected?.parcels === item.parcels ? 'var(--green-button-color)' : '#F2f2f2', opacity: !selected ? 1 : selected?.parcels === item.parcels ? 1 : 0.5 }}
                      >
                        <input onChange={() => { }} type="checkbox" value={item.parcels} checked={selected && selected?.parcels === item.parcels ? true : false} />
                        <div className="divValues">
                          <p>{item.parcels < 10 && '0'}{item.parcels}x de <strong>{formatCurrency(item.total / item.parcels)}</strong></p>
                          <p>Total: {formatCurrency(item.total)}</p>
                        </div>
                        <div className="divCets">
                          <p><strong>Taxa ao mês</strong></p>
                          <p><strong>{(((item.total - transformValue(valueProposal)) / transformValue(valueProposal) * 100) / item.parcels).toFixed(2)}%</strong></p>
                          <hr />
                          <p>Taxa total</p>
                          <p>{((item.total - transformValue(valueProposal)) / transformValue(valueProposal) * 100).toFixed(2)}%</p>
                          <hr />
                          <p>IOF</p>
                          <p>{formatCurrency(item.iof)}</p>
                        </div>
                      </div>
                    )
                  })}
                  <button
                    id="buttonContinue"
                    className="buttonParcelContinue parcelPulse"
                    onClick={() => {
                      if (selected) {
                        nextStep()
                        HandleSteps(1)
                      } else {
                        setOpenDialogParcels(true)
                      }
                    }}
                  >Continuar</button>
                </div></>}

            <Tarja />

          </div>
        )

      case 5:
        return (
          <div className="divCardLimit">
            <StepIndicator />
            {/* <div className="divCongratulations">
              <p><strong>Parabéns!</strong></p>
              <img src={congratulationsImage} alt="congratulationsImage" />
            </div> */}
            <div className="divCongratulations">
              <p>SIMULAÇÃO DE EMPRÉSTIMO</p>
            </div>
            <div className="cardPreApproved" style={{ marginTop: '20px' }}>
              {preApproved ? <p>{formatCurrency(preApproved as number)}</p> : <CircularProgress />}
            </div>

            <p>é o valor pré-simulado para seu empréstimo!</p>

            <div className="containerButtonsProposal">
              <button
                onClick={() => {
                  getPrices(preApproved as number)
                  // setValueProposal(formatCurrency(preApproved as number))
                  // setVerifySteps(verifySteps + 1)
                  // setProgressIndicator(progressIndicator + 1)
                }}
              // >Quero o valor pré-aprovado!</button>
              >CONTINUAR</button>

              <button onClick={async () => {
                setOtherValue(true)
                // }}>Quero um valor menor</button>
              }}>Simular outro valor</button>

            </div>
            {otherValue &&
              <div className="otherValue">
                <p>Digite abaixo o valor desejado de até <strong>{formatCurrency(preApproved as number)}:</strong></p>
                <p>Aviso: Digite apenas múltiplos de {formatCurrency(10)}</p>

                <div className="valueCardLimit">
                  <input
                    inputMode="tel"
                    type="text"
                    value={moneyMask(valueProposal)}
                    onChange={(e) => {
                      setValueProposal(e.target.value)
                    }}
                    style={{
                      color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                      backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                      borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                    }} />
                  <div />
                  <button
                    style={{ marginBottom: '10px' }}
                    className={transformValue(valueProposal) > 0 && transformValue(valueProposal) <= Number(preApproved) ? 'buttonCardActive defaultMarginBottom' : 'buttonCardDisabled defaultMarginBottom'}
                    onClick={() => {
                      if (transformValue(valueProposal) <= 0 || transformValue(valueProposal) > Number(preApproved)) {
                        setMessageError(transformValue(valueProposal) <= 0 ? 'Digite o valor desejado para continuar' : 'O valor do empréstimo precisa ser menor ou igual a')
                        setOpenDefaultAlert(true)
                      }
                      else {
                        if (transformValue(valueProposal) < minValue) {
                          setOpenDefaultAlert(true)
                        } else {
                          if (transformValue(valueProposal) % 10 !== 0) {
                            setMessageError(`Digite apenas múltiplos de R$ 10. Ex.: ${formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}`)
                            setOpenRedonlyAlert(true)
                          } else {
                            getPrices(valueProposal)
                          }
                        }
                      }
                    }} >Continuar</button>
                </div>
              </div>
            }
            <Tarja />

          </div>
        )

      case 6:
        return (
          <div className="divParcel">
            <StepIndicator />

            <p>Escolha em <strong><u>quantas parcelas</u></strong> você quer simular seu empréstimo de <strong><u>{valueProposal}</u></strong></p>

            <div className="listParcels">
              {dataPrice.sort((a, b) => a.parcels - b.parcels).map((item: PriceInterface) => {
                const lowestRate: PriceInterface = dataPrice.reduce((prev: PriceInterface, current: PriceInterface) => {
                  return prev.parcels < current.parcels ? prev : current
                })
                return (
                  <div className={lowestRate.parcels === item.parcels && !selected ? "parcel parcelPulse" : "parcel"} key={item.parcels}
                    onClick={() => {
                      if (selected) {
                        if (selected.parcels === item.parcels) {
                          setselected(null)
                        } else {
                          const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                          setselected(filtered as PriceInterface)
                        }
                      } else {
                        const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                        setselected(filtered as PriceInterface)
                      }
                    }}
                    style={{ backgroundColor: selected?.parcels === item.parcels ? 'var(--green-button-color)' : '#F2f2f2', opacity: !selected ? 1 : selected?.parcels === item.parcels ? 1 : 0.5 }}
                  >
                    <input onChange={() => { }} type="checkbox" value={item.parcels} checked={selected && selected?.parcels === item.parcels ? true : false} />
                    <div className="divValues">
                      <p>{item.parcels < 10 && '0'}{item.parcels}x de <strong>{formatCurrency(item.total / item.parcels)}</strong></p>
                      <p>Total: {formatCurrency(item.total)}</p>
                    </div>
                    <div className="divCets">
                      <p>CET</p>
                      <p>{item.cet_am}% a.m</p>
                      <p>{item.cet_aa}% a.a</p>
                    </div>
                  </div>
                )
              })}
              <div className="parcel parcelDisabled" key={'18x'}>
                <input onChange={() => { }} type="checkbox" value='18' checked={false} />
                <div>
                  <p>18x de <strong>{formatCurrency(valueFor18 as number / 18)}</strong></p>
                  <p>Total: {formatCurrency(valueFor18 as number)}</p>
                </div>
              </div>
              <div className="parcel parcelDisabled" key={'24x'}>
                <input onChange={() => { }} type="checkbox" value='24' checked={false} />
                <div>
                  <p>24x de <strong>{formatCurrency(valueFor24 as number / 24)}</strong></p>
                  <p>Total: {formatCurrency(valueFor24 as number)}</p>
                </div>
              </div>
              <div className="info defaultMarginBottom">
                <p><strong>Autorização de compra:</strong> avise ao atendimento do seu cartão de crédito que
                  fará uma compra online no valor de
                  {/* @ts-ignore */}
                  <strong>{formatCurrency(dataPrice.find((i) => i.parcels === 12).total)}</strong></p>

              </div>
              {/* <button
                style={{ marginBottom: '10px' }}
                className={selected ? 'buttonCardActive' : 'buttonCardDisabled'}
                onClick={() => {
                  if (!selected) {
                    setOpenDefaultAlert(true)
                  }
                  else {
                    nextStep()
                  }
                }} >Continuar</button> */}
              <Tarja />

            </div>
          </div>
        )

      case 'dontHaveCard':
        return (
          <div className="ContainerDontHaveCard">
            <OperationError />
            <div className="title">
              <p>Operação indisponível</p>
            </div>
            <div className="description">
              <p>Que pena! infelizmente não podemos dar continuidade no momento. Você
                pode fazer o seguinte: </p>
              <ul>
                <li><strong>Indique a Ayude para um amigo</strong> que tenha cartão de crédito.</li>
                <li>Ou tente novamente assim que estiver com o seu <strong>próprio cartão de crédito.</strong></li>
              </ul>
            </div>
            <div className="containerButtons">
              <a href={`https://api.whatsapp.com/send?text=https://emprestimo.ayude.com.br`} target="_blank" className="targetWhatsApp">
                <button className="sendWhatsAppButton">
                  <img src={WhatsAppIcon} alt="WhatsAppIcon" />
                  <p>Enviar para um amigo</p>
                </button>
              </a>
              <button onClick={exit} className="exitButtonDocument">Sair</button>
            </div>

          </div>
        )

      case 'dontHaveDocument':
        return (

          <div className="ContainerDontHaveCard">
            <OperationError />
            <div className="title">
              <p>Operação indisponível</p>
            </div>
            <div className="description">
              <p>Que pena! Infelizmente não podemos dar continuidade no momento. Tente novamente <strong>assim
                que você estiver com o RG original ou CNH original em mãos.</strong></p>
            </div>
            <div className="containerButtons">
              <a /* href="/" */>
                <button onClick={exit} className="exitButton">Sair</button>
              </a>
            </div>

          </div>
        )

      case 'insufficientLimit':
        return (
          <div className="ContainerDontHaveCard">
            <OperationError />
            <div className="title">
              <p>Operação indisponível</p>
            </div>
            <div className="description">
              <p>Que pena! infelizmente não podemos dar continuidade no momento. Tente novamente assim que o seu
                cartão estiver com o <strong>saldo disponível para compras acima de {formatCurrency(preApproved as number)}.</strong>
              </p>
            </div>
            <div className="containerButtons">
              <a /* href="/" */>
                <button onClick={exit} className="exitButton">Sair</button>
              </a>
              <button onClick={() => {
                setVerifySteps(2)
                sendToShow("limit")
                setShowParcels(false)
                setShowPreApproved(false)
              }} className="exitButton">Voltar</button>
            </div>

          </div>
        )

      case 'cpfBlocked':
        return (
          <div className="ContainerDontHaveCard">
            <Grid item xs={12} sx={{
              backgroundColor: '#F5B000',
              minWidth: '100%',
              height: '151px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              m: '0px 0px 30px 0px',
            }}>
              <ErrorOutline sx={{
                fill: 'white !important',
                width: '60px',
                height: '60px'
              }} color="error" />
            </Grid>
            <Grid item xs={12} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '0px 80px',
              textAlign: 'center',
              gap: '5px'
            }}>
              <Typography variant="h2" sx={{ fontWeight: 'bold', mb: '10px' }}>INFORMATIVO</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: '10px' }}>SISTEMA DE ANÁLISE CADASTRAL CLEARSALE</Typography>
              <Typography style={{ fontSize: '2rem', color: 'red' }}>Não foi possível darmos continuidade em sua proposta devido a inconsistências cadastrais</Typography>
              <Typography style={{ fontSize: '2rem' }}>Os dados cadastrais inseridos não foram aprovados na validação cadastral clearsale</Typography>

              <Typography sx={{ fontWeight: 'bold', mt: '10px', fontSize: '2rem', textDecoration: 'underline' }}>Prováveis motivos para recusa:</Typography>
              <ul style={{ textAlign: 'left' }}>
                <li style={{
                  padding: '0px 10px'
                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Número de telefone recente ou cadastrado em outro CPF;</Typography></li>
                <li style={{
                  padding: '0px 10px'
                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Email criado recentemente ou sem atividades recentes;
                  </Typography></li>
                <li style={{
                  padding: '0px 10px'
                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Endereço errado ou mudou-se recentemente;
                  </Typography></li>
                <li style={{
                  padding: '0px 10px'
                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Documentos emitidos ou extraviados recentemente;
                  </Typography></li>
                <li style={{
                  padding: '0px 10px'
                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Email, telefone ou CPF envolvidos em fraudes ou em outras ocorrências</Typography></li>
              </ul>
              <Typography style={{ fontSize: '1.5rem', color: 'gray' }}>Sugerimos que identifique acima a inconsistência cadastral, atualize os dados e após 24 horas envie uma nova proposta.</Typography>
            </Grid>
            <div className="containerButtons">
              <button onClick={() => {
                window.open("https://ayude.com.br", "_self")
              }} className="exitButton">Entendi</button>
            </div>

          </div>
        )

    }
  }

  return (
    <div className="master">
      <Dialog open={openAlertValueInvalid} >
        <DialogTitle>
          <div style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            fontSize: '2.4rem',
            flexWrap: 'wrap'
          }}>
            <img
              src={WarningIcon}
              style={{
                minWidth: '5rem',
                height: '4rem'
              }} />
            <strong>SALDO DISPONÍVEL INFORMADO INCORRETAMENTE</strong>
          </div>
        </DialogTitle>
        <DialogContent>
          <p style={{ textAlign: 'center', textTransform: 'uppercase', marginBottom: '20px', fontSize: '1.7rem' }}>
            Verifique o saldo que está disponível para compras no seu cartão de crédito e digite corretamente <strong>(Inclusive os centavos)</strong>
          </p>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <button
            style={{
              width: 'auto',
              fontSize: '1.7rem',
              height: 'auto',
              backgroundColor: 'var(--blue-text-color)',
              color: 'white',
              padding: '1.5rem 2rem',
              border: 'none',
              borderRadius: '4px',
            }}
            onClick={() => {
              setOpenAlertValueInvalid(false)
            }}
          >
            Entendi, digitarei o saldo disponível correto
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAlertDeath} >
        <DialogTitle>
          <div style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            fontSize: '2.4rem',
            flexWrap: 'wrap'
          }}>
            <img
              src={WarningIcon}
              style={{
                minWidth: '5rem',
                height: '4rem'
              }} />
            <strong>CPF INFORMADO COM ÓBITO REGISTRADO</strong>
          </div>
        </DialogTitle>
        <DialogContent>
          <p style={{ textAlign: 'center', textTransform: 'uppercase', marginBottom: '20px', fontSize: '1.7rem' }}>
            Lamento informar que não é possível continuar devido ao óbito registrado no CPF informado.
          </p>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <button
            style={{
              width: 'auto',
              minWidth: '20rem',
              fontSize: '1.7rem',
              height: 'auto',
              backgroundColor: 'var(--blue-text-color)',
              color: 'white',
              padding: '1.5rem 2rem',
              border: 'none',
              borderRadius: '4px',
            }}
            onClick={() => {
              setOpenAlertDeath(false)
              resetProposal()
            }}
          >
            Sair
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogSameValue}>
        <DialogContent className="containerSameValue">
          <p style={{
            fontSize: '16px',
            textAlign: 'center'
          }}>Você já recebeu o valor de {moneyMask(valueProposal)} hoje.</p>
          <p style={{
            fontSize: '16px',
            textAlign: 'center'
          }}>Sugerimos que solicite um valor diferente para essa proposta.
          </p>
          <p style={{
            fontSize: '16px',
            textAlign: 'center'
          }}>Motivo: Por segurança não é permitido solicitar valores iguais no mesmo dia.</p>
          <button onClick={() => setOpenDialogSameValue(false)} className="buttonContinue">Entendi</button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDialogError}>
        <p><strong>Digite o saldo disponível do seu cartão</strong> de crédito para continuar</p>
        <button onClick={() => setOpenDialogError(false)}>Digitar</button>
      </Dialog>
      <Dialog open={openDialogParcels}>
        <p>Selecione a quantidade de parcelas antes de continuar</p>
        <button onClick={() => setOpenDialogParcels(false)}>Selecionar</button>
      </Dialog>
      <Dialog open={openDefaultAlert}>
        {verifySteps === 2 && transformValue(valueProposal) < minValue && transformValue(valueProposal) > 0 ? <p style={{
          fontSize: '18px',
          padding: '0px 20px'
        }}>O valor mínimo para empréstimo é de <strong>R$ {minValue},00</strong></p> : <p className="dialogGeneric">{messageError} {transformValue(valueProposal) > Number(preApproved) && `${formatCurrency(preApproved as number)}`}</p>}
        <button onClick={() => setOpenDefaultAlert(false)}>{verifySteps === 6 ? "Selecionar" : "Digitar"}</button>
      </Dialog>
      <Dialog open={redonlyAlert}>
        <p>Digite apenas múltiplos de R$ 10. Ex.: {formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}</p>
        <button onClick={() => setOpenRedonlyAlert(false)}>Digitar</button>
      </Dialog>

      <Dialog open={postalCodeAlert}>
        {
          formatinputs.toString(postalCode).length === 8 ?
            <p>Digite um CEP válido para continuar</p>
            :
            <p>Digite seu CEP para continuar</p>
        }
        <button onClick={() => setPostalCodeAlert(false)}>Digitar</button>
      </Dialog>

      <Dialog open={cpfAlert}>
        {
          formatinputs.toString(CPF).length === 11 ?
            <p>Digite um <strong>CPF</strong> válido para continuar</p>
            :
            <p>Digite seu <strong>CPF</strong> para continuar</p>
        }
        <button onClick={() => setCpfAlert(false)}>Digitar</button>
      </Dialog>

      <Dialog open={openSummary} onClose={() => {
        setOpenSummary(false)
        setselected(null)
      }} className="dialogAlert">
        <>
          <p className="titleAlert">CONFIRMACÃO</p>

          <p>O valor do saldo a ser utilizado em <u>seu cartão de crédito</u> é <strong>{formatCurrency(selected?.total as number)}</strong></p>

          <p>Parcelado em <strong>{selected?.parcels as number}x</strong> iguais de <strong>{formatCurrency(selected?.parcelAmount as number)}</strong> </p>

          <p>Você receberá em sua conta o valor líquido de <strong>{valueProposal}</strong></p>

          <p className="titleAlert" style={{ color: 'red' }}>ATENCÃO!</p>


          <p>
            <strong>Passo 1</strong> - Verifique se o saldo disponível em seu cartão é maior que <u><strong>{formatCurrency(selected?.total as number)}</strong></u>
          </p>

          <p>
            <strong> Passo 2</strong> – Entre em contato com a operadora do seu cartão e <p style={{ color: 'red', margin: '0px' }}>solicite a liberação imediata para compras online.</p>
          </p>


          <p>
            <strong><u>Somente após seguir os 2 passos acima,</u></strong> clique em continuar, caso contrário <strong><u style={{ color: 'red' }}>seu cartão poderá ser bloqueado.</u></strong>

          </p>
        </>

        <button
          style={{ padding: '10px' }}
          className="alertButtonContinue"
          onClick={() => {
            nextStep()
            setOpenSummary(false)
          }}
        >
          CONTINUAR
        </button>
        <button
          style={{ padding: '10px' }}
          className="buttonSimulatorOther"
          onClick={() => {
            // setVerifySteps(Number(verifySteps) - 1)
            // setProgressIndicator(progressIndicator - 1)
            setOpenSummary(false)
            setselected(null)
            sendToShow("otherValue")
            setOtherValue(true)
          }}
        >
          Simular outro valor
        </button>
      </Dialog>
      <Dialog open={openFormError} className="DialogRegister">
        <p>Por favor siga as instruções abaixo para prosseguir:</p>
        <ul>
          {errorList.map((item) => {
            return <li key={item.path}>{item.message}</li>
          })}
        </ul>
        <button onClick={() => setOpenFormError(false)}>Entendi</button>
      </Dialog>

      <Dialog open={errorSintegra}>
        <div className="sintegraError">
          <p>O SISTEMA DE PESQUISA DE CPF (SINTEGRA) ESTÁ FORA DO AR.
          </p>
          <p>SOLICITAMOS QUE TENTE NOVAMENTE MAIS TARDE!</p>

        </div>
        <button
          onClick={() => {
            resetProposal()
            setErrorSintegra(false)
          }}
        >ENTENDI</button>
      </Dialog>

      <Dialog open={limitForMonth}>
        <div className="dialogTimer">
          <p>Você já solicitou o valor máximo de empréstimo no mês.</p>
        </div>
        <button
          onClick={() => setLimitForMonth(false)}
        >Entendi</button>
      </Dialog>

      <Dialog open={openPlayer} >
        <div className="ContainerPlayer">
          <p><strong>Assista com atenção</strong>
            <br />
            (Duração do vídeo 1 minuto)
          </p>
          <div className="player">
            <ReactPlayer
              controls={true}
              width="100%"
              height="100%"
              light={<img src={Thumbnail} alt='Thumbnail' style={{
                width: '100%',
                height: '100%'
              }} />}
              url={'https://tc-online-invoices.s3.sa-east-1.amazonaws.com/images-templates/BAIXA+RESOLU%C3%87%C3%83O(2).mp4'}
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // onPlay={() => {
            //   setTimeout(() => {
            //     setDisabledButtons(false)
            //   }, 60000);
            // }}
            />
          </div>
          <button
            disabled={disabledButtons}
            onClick={() => {
              setOpenPlayer(false)
              setProgressIndicator(1)
              setVerifySteps(Number(verifySteps) + 1)
            }}
            style={{ opacity: disabledButtons ? 0.4 : 1 }}
            className="buttonPlayerContinue"
          >Eu tenho cartão de crédito e quero solicitar o empréstimo</button>
          <button
            disabled={disabledButtons}
            onClick={() => setOpenPlayer(false)}
            className="buttonDontInterest"
            style={{ opacity: disabledButtons ? 0.4 : 1 }}>Não tenho interesse</button>
        </div>
      </Dialog>

      <Dialog open={openModalPendingProposal}>
        <p style={{ fontSize: '4.5rem', padding: '5px', fontWeight: 'bold' }}>Você já tem uma proposta aguardando ser finalizada.</p>
        <p style={{ fontSize: '3.5rem', padding: '5px' }}>Conclua a proposta antes de criar uma nova</p>
        <button
          style={{
            width: 'auto',
            height: 'auto',
            padding: '20px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => {
            if (pendingProposalLink !== "") {
              window.location.replace(pendingProposalLink)
            } else {
              setOpenModalPendingProposal(false)
            }
          }}
        >{pendingProposalLink !== "" ? "Concluir proposta" : "Entendi"}</button>
      </Dialog>

      {!loading && render()}
    </div>
  )
}