import React, { useContext, useEffect, useState } from 'react'
import { GenderOptionsProps, SelectComponent } from '../../form/select'
import { InputComponent } from '../../form/input'
import { StepContext } from '../../context'
import banks from '../../../assets/conta_bancaria.json'
import { formatAccount } from '../../../utils/formatAccount'
import { StepIndicator } from '../../stepIndicator'
import { Tarja } from '../../tarja'
import { IIValidatorDataBanks } from '../../@interfaces'
import { formatinputs } from '../../../utils/formatCpf'
import Axios from 'axios'
import { step3 } from '../register/validator'
import { Dialog } from '@mui/material'
import { formatCurrency } from '../../../utils/formatCurrency'
import { transformValue } from '../../../utils/transformValue'
import './styles.css'
import { useSnackbar } from 'notistack'
import { awaitTime } from '../../../utils/timing'
import { io } from 'socket.io-client'

export function Account() {
    const {
        name,
        CPF,
        setLoading,
        proposalToken,
        valueProposal,
        setAssertiva,
        setStep,
        setProgressIndicator,
        progressIndicator,
        proposalNumber,
        openAccountSuspect,
        setOpenAccountSuspect,
        HandleSteps
    } = useContext(StepContext)
    const { enqueueSnackbar } = useSnackbar()
    const [bank, setBank] = useState<string>('')
    const [agency, setAgency] = useState<string>('')
    const [account, setAccount] = useState<string>('')
    const [accountType, setAccountType] = useState<string>('')
    const [errorList, setErrorList] = useState<{ message: string; path: string }[]>([])
    const [openFormError, setOpenFormError] = useState<boolean>(false)
    const [openFormErrorTransfeera, setOpenFormErrorTransfeera] = useState(false)
    const [transfeeraError, setTransfeeraError] = useState([])
    const accountTypes: GenderOptionsProps[] = [
        {
            label: "Conta Corrente",
            value: "CONTA_CORRENTE"
        },
        {
            label: "Conta Poupança",
            value: "CONTA_POUPANCA"
        },
    ]

    useEffect(() => {
        (async () => {
            if (proposalNumber) {
                const socket = io(process.env.REACT_APP_ONLINE_WS as string);
                socket.on(proposalNumber.toString(), async (...args) => {
                    if (args[0] === "aprove_account") {
                        await getAssertivaLink()
                        setOpenAccountSuspect(false)
                    }
                });
            }
        })()
    }, [proposalNumber])


    const sendToTransfeera = async () => {
        setLoading(true)
        await step3.validate({
            bank,
            agency_number: agency,
            account,
            account_type: accountType,
        }, { abortEarly: false }).then(async () => {
            const accountUser = {
                account: {
                    name: name,
                    cpf_cnpj: formatinputs.toString(CPF),
                    bank_code: banks.banks.find((item) => item.name === bank)?.code as string,
                    account: account.substring(0, account.length - 1),
                    account_digit: account.substring(account.length - 1),
                    agency: agency,
                    account_type: accountType,
                    bank_name: bank
                }
            }
            console.log(accountUser)
            await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals`, accountUser, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': proposalToken,
                }
            }).then(async () => {
                HandleSteps(4)
                return getAssertivaLink()
            }).catch((error) => {
                if (error.response.data.message?.suspect_fraud) {
                    setOpenAccountSuspect(true)
                } else {
                    setTransfeeraError(error.response.data.message.errors)
                    if (error.response.data.point === 'transfeera') {
                        setOpenFormErrorTransfeera(true)
                    } else {
                        enqueueSnackbar("Falha ao validar conta!", {
                            variant: 'error'
                        })
                    }
                }
                setLoading(false)
            })
        }).catch(async (err) => {
            const array: { message: string; path: string }[] = []
            await err.inner.forEach((element: any) => {

                if (array.filter(e => e.path === element.path).length === 0) {
                    array.push({ message: element.message, path: element.path })
                }
            })
            setErrorList(array)
            setOpenFormError(true)
            setLoading(false)
        })

    }

    const getAssertivaLink = async (tries: number = 0) => {
        await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
            headers: {
                'Content-Type': 'application/json',
                'x-token': proposalToken,
            }
        }).then(async ({ data }) => {
            if (data.proposal.integrations?.assertiva?.link) {
                setAssertiva(data.proposal.integrations?.assertiva?.link ?? "")
                setStep(2)
                setProgressIndicator(progressIndicator + 1)
                setLoading(false)
            } else if (tries >= 30) {
                setStep(2)
                setProgressIndicator(progressIndicator + 1)
                setLoading(false)
            } else {
                await awaitTime(2)
                return getAssertivaLink(tries + 1)
            }
        }).catch(async () => {
            await awaitTime(2)
            return getAssertivaLink(tries + 1)
        })
    }

    return (
        <div className="container">
            <Dialog open={openFormError} className="DialogRegister">
                <p>Por favor siga as instruções abaixo para prosseguir:</p>
                <ul>
                    {errorList.map((item) => {
                        return <li key={item.path}>{item.message}</li>
                    })}
                </ul>
                <button onClick={() => setOpenFormError(false)}>Entendi</button>
            </Dialog>
            <Dialog open={openAccountSuspect} className="DialogRegister">
                <p style={{
                    padding: '10px 20px'
                }}>Aguarde! <br /><br />Estamos analisando seus dados bancários.</p>
            </Dialog>
            <Dialog open={openFormErrorTransfeera} className="ContainerTransfeera">
                <p>Por favor siga as instruções abaixo para prosseguir:</p>
                <ul>
                    {transfeeraError.map((item: {
                        message: string,
                        erroCode: string,
                        field: string
                    }) => {
                        if (item.message) {
                            return (
                                <li>{item.message}</li>
                            )
                        }
                    })}
                </ul>
                <p>Obs: Informe a agência SEM DÍGITO.</p>
                <button onClick={() => {
                    setOpenFormErrorTransfeera(false)
                }}>Entendi</button>
            </Dialog>
            <StepIndicator />

            <div className="containerTitleAccount">
                <strong>INFORME SUA CONTA BANCÁRIA</strong>

                <div className="infoCardAccount">
                    <p>É através desta conta bancária que receberá seu empréstimo de <span>{formatCurrency(transformValue(valueProposal))}</span></p>
                </div>
            </div>
            <InputComponent label="Nome completo" type="tel" placeholder="Digite aqui..." value={name} autoDisabled />
            <InputComponent label="CPF" type="tel" placeholder="Digite aqui..." value={CPF} autoDisabled />
            <InputComponent label="Conta para crédito" type="tel" placeholder="Digite aqui..." value={'PESSOA FÍSICA - PF'} autoDisabled />
            <SelectComponent label="Tipo de conta" genderOptions={accountTypes} value={accountType} onChange={(e) => setAccountType(e.target.value)} />
            <SelectComponent label="Banco" bankOptions={banks.banks} value={bank} onChange={(e) => setBank(e.target.value)} />
            <InputComponent label="Agência (sem dígito)" type="tel" placeholder="Digite aqui..." value={agency} onChange={(e) => {
                if (e.target.value.length <= 4) {
                    setAgency(e.target.value)
                }
            }} />
            <InputComponent label="Conta e Dígito" type={bank === "Banco do Brasil S.A." ? "text" : "tel"} placeholder="Digite aqui..." value={formatAccount(account)}
                onChange={(e) => {
                    let canUseDigitX = () => {
                        if (bank === "Banco do Brasil S.A.") {
                            return e.target.value.slice(-1) === "x" || e.target.value.slice(-1) === "X"
                        } else {
                            return Number(e.target.value.slice(-1)) >= 0
                        }
                    }
                    //@ts-ignore
                    if ((!isNaN(e.target.value.slice(-1)) || canUseDigitX()) && e.target.value.length <= 15 && e.target.value.slice(-1) !== " ") {
                        setAccount(e.target.value.replaceAll('-', '').replaceAll('.', '').replaceAll(',', ''))
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                        setAccount(account.substring(0, account.length - 1))
                    }
                }}
                onPaste={async (e) => {
                    setLoading(true)
                    let value = e.clipboardData.getData('text').replace(/\D/g, '')
                    await awaitTime(2)
                    setAccount(value)
                    setLoading(false)
                }}
            />
            <button className="buttonContinue" onClick={() => {
                sendToTransfeera()

            }}>Continuar</button>
            <div className='ContainerTextTransfeera'>
                <p style={{ color: 'red' }}>Obs: Para validação da sua conta bancária, enviaremos um depósito no valor de R$ 0,01 em nome de Nacional Pagamentos.</p>
            </div>
            <Tarja />
        </div >
    )
}

