export async function awaitTime(time: number) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time * 1000)
  })
}

export function isOfficeHour(): boolean {
  let holidays = [
    '12/10/2023',
    '02/11/2023',
    '15/11/2023',
    '25/12/2023',
    '01/01/2024',
    '12/02/2024',
    '13/02/2024',
    '29/03/2024',
    '21/04/2024',
    '01/05/2024',
    '30/05/2024',
    '07/09/2024',
    '12/10/2024',
    '02/11/2024',
    '15/11/2024',
    '20/11/2024',
    '25/12/2024',
  ]
  const days = {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  }

  const date = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const rightDate = new Date(year, month, day)
  console.log(date.toLocaleString())
  if (rightDate.getDay() === 6 || rightDate.getDay() === 0) {
    return false
  } else if (holidays.includes(rightDate.toLocaleString().split(',')[0])) {
    return false
  } else {
    if (hours === 16 && minutes < 20) {
      return true
    }
    else if (hours > 15 && hours < 23) {
      return false
    } else if (hours === 23 && minutes < 50) {
      return false
    } else {
      return true
    }
  }
}