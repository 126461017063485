import './styles.css'
import arrow from '../../assets/arrow.svg'
import React, { useContext } from 'react'

import { StepContext } from '../../components/context'

export const StepIndicator = () => {
  const { progressIndicator, changeStep, disabledProgresIndicator } = useContext(StepContext)

  const progressIndicatorStyle = { "--progress": progressIndicator * 19.6 } as React.CSSProperties
  return (
    <div>
      <div className="progressIndicator">
        <button
          style={{opacity: disabledProgresIndicator ? 0.2 : 1}}
          disabled={disabledProgresIndicator}
          onClick={changeStep}
        >
          <img src={arrow} alt="arrow" />
        </button>
        <p>{progressIndicator <= 0 ? 'Primeiros passos' : ''}</p>
        <p className='indicatorNumber'>{progressIndicator}/6</p>
      </div>
      <div className='indicationBar' style={progressIndicatorStyle} />
    </div>
  )
}