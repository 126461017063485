import React from "react";
import {
  BrowserRouter,
  Routes as Router,
  Route,
} from "react-router-dom";
import { Home } from "../home";

export function Routes() {
  return (
    <BrowserRouter>
      <Router>
        <Route path="/" element={<Home/>}/>
        <Route path="/:linkID" element={<Home/>}/>
        <Route path="/uf/:userId" element={<Home/>}/>
        <Route path="/propostas" element={<Home proposal/>}/>
      </Router>
    </BrowserRouter>
  )
}
