import { QRCodeSVG } from 'qrcode.react';
import logoTC from '../../assets/logo_ayude.png'
import './styles.css'
import { StepContext } from "../context"
import { useContext } from 'react';
import hand from '../../assets/mao_com_celular_na_tela_do_app_troca_creditos.png'

export default function ComputerAccess() {
  const { href } = useContext(StepContext)

  return (
    <div className="containerComputerAccess">
      <h1>Seja bem-vindo(a) à</h1>
      <img src={logoTC} alt="logoTC" className='logoComputerAccess' />
      <h2>PARA REALIZAR SEU EMPRÉSTIMO É NECESSÁRIO
        <br />
        <strong>ACESSAR O SITE PELO CELULAR</strong>
      </h2>
      <h3>(Abra a câmera do seu celular e aponte para o QRCODE)</h3>
      <QRCodeSVG value={href} className="qrCode" />
      <img src={hand} alt="hand" className='hand' />
    </div>
  )
}