import { ChangeEvent, createContext, useEffect, useLayoutEffect, useState } from "react"
import { awaitTime } from "../../utils/timing";
import { CheckoutInterface, ChildProps, ContextProps, LinkInterface, PriceInterface, ProposalProps } from "../@interfaces";



export const StepContext = createContext({} as ContextProps)

export const ContextProvider = ({ children }: ChildProps) => {

  const [step, setStep] = useState<number>(-1) // 0
  const [keepGoing, setKeepGoing] = useState<boolean>(true)
  const [linkId, setLinkId] = useState<string | undefined>('')
  const [proposal, setProposal] = useState<ProposalProps>({})
  const [cardLimit, setCardLimit] = useState<string>('0')
  const [valueProposal, setValueProposal] = useState<string>('0')
  const [dataLink, setDataLink] = useState<LinkInterface[]>([])
  const [dataPrice, setDataPrice] = useState<PriceInterface[]>([])
  const [progressIndicator, setProgressIndicator] = useState<number>(0) //0
  const [verifySteps, setVerifySteps] = useState<number | string>(1) //1
  const [registerSteps, setRegisterSteps] = useState<number>(1) //1
  const [proposalToken, setProposalToken] = useState<string | null>('')
  const [operationSuccess, setOperationSuccess] = useState<boolean>(true)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [postalCode, setPostalCode] = useState<string>('')
  const [name, setName] = useState<string>('');
  const [CPF, setCPF] = useState<string>('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');
  const [checkoutProposal, setCheckoutProposal] = useState<CheckoutInterface | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [idwallSteps, setIdwallSteps] = useState<number>(1)
  const [getStatus, setGetStatus] = useState(false)
  const [disabledProgresIndicator, setDisabledProgresIndicator] = useState(false)
  const [defaultUserID, setDefaultUserID] = useState<string>('')
  const [href, setHref] = useState('https://emprestimo.ayude.com.br')
  const [saleTable, setSaleTable] = useState('')
  const [preApproved, setPreApproved] = useState<number>()
  const [proposalNumber, setProposalNumber] = useState<number | undefined>()
  const [checkoutSteps, setCheckoutSteps] = useState<number>(4)
  const [proposalAmounts, setProposalAmounts] = useState<{
    total: number,
    parcels: number,
    value_parcels: number
  } | null>(null)
  const [birthday, setBirthday] = useState<string>('');
  const [gender, setGender] = useState('');
  const [assertiva, setAssertiva] = useState('')
  const [sintegraError, setSintegraError] = useState<boolean>(false)
  const [ownerTyping, setOwnerTyping] = useState<boolean>(false)
  const [challengeId, setChallengeId] = useState('')
  const nextStepLinks = ["2123f8d9-0a33-419c-bcd9-2219c9c5596f"]
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false)
  const [openAccountSuspect, setOpenAccountSuspect] = useState(false)
  const [openModalContactReason, setOpenModalContactReason] = useState(false)

  const [backProposal, setBackProposal] = useState<any>({}) 


  const marketing = {
    0: '0: Solicitar empréstimo',
    1: '1: Pré cadastro & simulação',
    2: '2: Cadastro',
    3: '3: Cartão de crédito',
    4: '4: Conta bancária',
    5: '5: Fotos',
    6: '6: Assinatura',
    7: '7: Empréstimo concluído'
  }

  useEffect(() => {
    if (progressIndicator > 2) {
      setDisabledProgresIndicator(true)
    } else if (progressIndicator === 9) {
      setDisabledProgresIndicator(false)
    }
  }, [progressIndicator])

  const handleMarketing = (param: string) => {
    console.log(param)
    const value = param.replaceAll(' ', '').replace(':', '').toLowerCase()
    console.log(value)
    /* @ts-ignore */
    window.gtag('event', value)

    /* @ts-ignore */
    window.fbq('trackCustom', param, {

      dominio: 'emprestimo.ayude.com.br',

      pagina: 'todas paginas',

      fonte: 'todas fontes de trafego'
    });
  }

  function changeStep() {
    switch (step) {
      case 0:
        if (Number(verifySteps) <= 6) {
          setVerifySteps(Number(verifySteps) - 1)
        }

        setProgressIndicator(progressIndicator - 1)

        break
      case 1:
        setProgressIndicator(progressIndicator - 1)
        if (registerSteps === 2) {
          setStep(step - 1)
        } else {
          setRegisterSteps(registerSteps - 1)
        }
        break
      case 2:
        setStep(step - 1)
        setProgressIndicator(progressIndicator - 1)
        setRegisterSteps(2)
        break
      case 3:
        setStep(step - 1)
        setProgressIndicator(progressIndicator - 1)
      case 4:
        setStep(step - 1)
        setProgressIndicator(progressIndicator - 1)
    }

  }

  const HandleSteps = (step: number) => {
    console.log('step - ', step)
    handleMarketing(marketing[step as keyof typeof marketing])

  }

  // useEffect(() => {
  //   (() => {
  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //     if (progressIndicator > 0) {
  //       HandleSteps(progressIndicator - 1)
  //     }
  //   })()
  // }, [progressIndicator])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-step', step.toString())
    })()
  }, [step])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-openModalConfirmation', openModalConfirmation.toString())
    })()
  }, [openModalConfirmation])


  useEffect(() => {
    if (linkId !== '') sessionStorage.setItem('tc-linkId', linkId as string)
  }, [linkId])

  useEffect(() => {
    sessionStorage.setItem('tc-checkoutSteps', checkoutSteps.toString())
  }, [checkoutSteps])

  useEffect(() => {
    if (valueProposal !== '0') sessionStorage.setItem('tc-valueProposal', valueProposal)
  }, [valueProposal])

  useEffect(() => {
    if (CPF !== '') sessionStorage.setItem('tc-CPF', CPF)
  }, [CPF])

  useEffect(() => {
    if (cellphone !== '') sessionStorage.setItem('tc-cellphone', cellphone)
  }, [cellphone])

  useEffect(() => {
    if (email !== '') sessionStorage.setItem('tc-email', email)
  }, [email])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-sintegraError', JSON.stringify(sintegraError))
    })()
  }, [sintegraError])

  useEffect(() => {
    if (name !== '') sessionStorage.setItem('tc-name', name)
  }, [name])

  useEffect(() => {
    if (gender !== '') sessionStorage.setItem('tc-gender', gender)
  }, [gender])

  useEffect(() => {
    if (birthday !== '') sessionStorage.setItem('tc-birthday', birthday)
  }, [birthday])

  useEffect(() => {
    if (assertiva !== '') sessionStorage.setItem('tc-assertiva', assertiva)
  }, [assertiva])

  useEffect(() => {
    if (proposalToken !== '') sessionStorage.setItem('tc-proposalToken', proposalToken as string)
  }, [proposalToken])

  useEffect(() => {
    if (postalCode !== '') sessionStorage.setItem('tc-postalCode', postalCode)
  }, [postalCode])

  useEffect(() => {
    if (cardLimit !== '0') sessionStorage.setItem('tc-cardLimit', cardLimit)
  }, [cardLimit])

  useEffect(() => {
    if (saleTable !== '') sessionStorage.setItem('tc-saleTable', saleTable)
  }, [saleTable])

  useEffect(() => {
    if (preApproved) sessionStorage.setItem('tc-preApproved', JSON.stringify(preApproved))
  }, [preApproved])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-progressIndicator', progressIndicator.toString())
    })()
  }, [progressIndicator])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-verifySteps', verifySteps.toString())
    })()
  }, [verifySteps])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-registerSteps', registerSteps.toString())
    })()
  }, [registerSteps])

  useEffect(() => {
    if (JSON.stringify(proposal) !== '{}') sessionStorage.setItem('tc-proposal', JSON.stringify(proposal))
  }, [proposal])

  useEffect(() => {
    if (JSON.stringify(dataLink) !== '[]') sessionStorage.setItem('tc-dataLink', JSON.stringify(dataLink))
  }, [dataLink])

  useEffect(() => {
    if (JSON.stringify(dataPrice) !== '[]') sessionStorage.setItem('tc-dataPrice', JSON.stringify(dataPrice))
  }, [dataPrice])

  useEffect(() => {
    if (checkoutProposal) sessionStorage.setItem('tc-checkoutProposal', JSON.stringify(checkoutProposal))
  }, [checkoutProposal])

  useEffect(() => {
    if (linkId && nextStepLinks.includes(linkId)) {
      setProgressIndicator(1)
      setVerifySteps(2)
    }
  }, [linkId])

  useEffect(() => {
    if (verifySteps === 2 && nextStepLinks.includes(linkId as string) && step === 0) {
      setDisabledProgresIndicator(true)
    } else {
      setDisabledProgresIndicator(false)
    }
  }, [verifySteps, step])

  useEffect(() => {
    (async () => {
      setLoading(true)
      await awaitTime(1)
      const sessionStep = sessionStorage.getItem('tc-step')
      const sessionProgressIndicator = sessionStorage.getItem('tc-progressIndicator')
      const sessionProposal = sessionStorage.getItem('tc-proposal')
      const sessionDataLink = sessionStorage.getItem('tc-dataLink')
      const sessionDataPrice = sessionStorage.getItem('tc-dataPrice')

      const sessionRegister = sessionStorage.getItem('tc-registerSteps')
      const sessionVerify = sessionStorage.getItem('tc-verifySteps')

      const sessionValueProposal = sessionStorage.getItem('tc-valueProposal')
      const sessionCardLimit = sessionStorage.getItem('tc-cardLimit')
      const sessionSaleTable = sessionStorage.getItem('tc-saleTable')


      const sessionCPF = sessionStorage.getItem('tc-CPF')
      const sessionCellphone = sessionStorage.getItem('tc-cellphone')
      const sessionEmail = sessionStorage.getItem('tc-email')
      const sessionpostalCode = sessionStorage.getItem('tc-postalCode')
      const sessionproposalToken = sessionStorage.getItem('tc-proposalToken')

      const sessionCheckoutProposal = sessionStorage.getItem('tc-checkoutProposal')
      const sessionLinkID = sessionStorage.getItem('tc-linkId')

      const sessionIdWallSteps = sessionStorage.getItem('tc-idwallSteps')
      const sessionCheckoutSteps = sessionStorage.getItem('tc-checkoutSteps')
      const sessionName = sessionStorage.getItem('tc-name')
      const sessionGender = sessionStorage.getItem('tc-gender')
      const sessionBirthday = sessionStorage.getItem('tc-birthday')
      const sessionAssertiva = sessionStorage.getItem('tc-assertiva')
      const sessionSintegraError = sessionStorage.getItem('tc-sintegraError')
      const sessionPreAproved = sessionStorage.getItem('tc-preApproved')
      const sessionopenModalConfirmation = sessionStorage.getItem('tc-openModalConfirmation')

      setStep(sessionStep ? Number(sessionStep) : 0)
      if (sessionVerify && Number(sessionProgressIndicator) !== 0) {
        setProgressIndicator(Number(sessionProgressIndicator))
      }
      setProposal(JSON.parse(sessionProposal as string) ?? {})
      setDataLink(JSON.parse(sessionDataLink as string) ?? [])
      setDataPrice(JSON.parse(sessionDataPrice as string) ?? [])


      if (sessionVerify) {
        setVerifySteps(!Number(sessionVerify) ? sessionVerify : Number(sessionVerify))
      }
      setValueProposal(sessionValueProposal ? sessionValueProposal : '0')
      setCardLimit(sessionCardLimit ? sessionCardLimit : '0')
      setSaleTable(sessionSaleTable ?? '')

      setCPF(sessionCPF ?? '')
      setPostalCode(sessionpostalCode ?? '')
      setCellphone(sessionCellphone ?? '')
      setEmail(sessionEmail ?? '')

      setProposalToken(sessionproposalToken ? sessionproposalToken : '')
      setCheckoutProposal(sessionCheckoutProposal ? JSON.parse(sessionCheckoutProposal) : null)
      setIdwallSteps(Number(sessionIdWallSteps) ?? 1)
      if (sessionLinkID) {
        setLinkId(sessionLinkID)
      }
      setCheckoutSteps(Number(sessionCheckoutSteps) ?? checkoutSteps)
      setName(sessionName ?? '')
      setGender(sessionGender ?? '')
      setBirthday(sessionBirthday ?? '')
      setAssertiva(sessionAssertiva ?? '')
      setRegisterSteps(sessionRegister ? Number(sessionRegister) : 1)
      setSintegraError(sessionSintegraError === "true" ? true : false)
      setOpenModalConfirmation(sessionopenModalConfirmation === "true" ? true : false)
      if (sessionPreAproved) {
        setPreApproved(Number(sessionPreAproved))
      }
    })()

  }, [])

  const handleDates = (e: ChangeEvent<any>, setFunction: any, inputId: string) => {

    const value = e.target.value
    const [day, month, year] = [value.split('/')[0], value.split('/')[1], value.split('/')[2]]
    const [day1, day2, month1, month2] = [day.split('')[0], day.split('')[1], month.split('')[0], month.split('')[1]]
    const input = document.getElementById(inputId)
    // dia 1 entre 0 & 3
    if (day1 !== '_') {
      if (Number(day1) > 3) {
        setFunction(`3_/__/____`)
        window.setTimeout(function () {
          /*@ts-ignore*/
          input.setSelectionRange(1, 1);
        }, 0);
      } else {
        setFunction(value)
      }
    } else {
      setFunction(value)
    }
    //dia 2: se dia 01 for igual a 0 e dia 01 for igual a 0, logo day será 01; se dia 1 for 3 e dia 2 for maior que 1, logo dia será 31
    if (day2 !== '_') {
      if (day1 === '3') {
        if (Number(day2) > 1) {
          setFunction('31/__/____')
        } else {
          setFunction(value)
        }
      }

      if (day1 === '0') {
        if (day2 === '0') {
          setFunction(`01/__/____`)
          window.setTimeout(function () {
            /*@ts-ignore*/
            input.setSelectionRange(3, 3);
          }, 0);
        } else {
          setFunction(value)
        }
      }


    }
    // mes entre 01 e 12

    if (month1 !== '_') {
      if (Number(month1) > 1) {
        setFunction(`${day1}${day2}/0${month1}/____`)
        window.setTimeout(function () {
          /*@ts-ignore*/
          input.setSelectionRange(6, 6);
        }, 0);
      }
      if (Number(month1) === 1) {
        if (month2 > 2) {
          setFunction(`${day1}${day2}/12/____`)
          window.setTimeout(function () {
            /*@ts-ignore*/
            input.setSelectionRange(6, 6);
          }, 0);
        } else {
          setFunction(value)
        }
      }
      if (Number(month1) === 0) {
        if (Number(month2) === 0) {
          setFunction(`${day1}${day2}/01/____`)
          window.setTimeout(function () {
            /*@ts-ignore*/
            input.setSelectionRange(6, 6);
          }, 0);
          return
        } else {
          setFunction(value)
        }
      }
    }
  }



  return (
    <StepContext.Provider
      value={{
        step,
        setStep,
        keepGoing,
        setKeepGoing,
        linkId,
        setLinkId,
        proposal,
        setProposal,
        cardLimit,
        setCardLimit,
        setValueProposal,
        valueProposal,
        dataLink,
        setDataLink,
        progressIndicator,
        setProgressIndicator,
        verifySteps,
        setVerifySteps,
        registerSteps,
        setRegisterSteps,
        setProposalToken,
        proposalToken,
        changeStep,
        operationSuccess,
        setOperationSuccess,
        setRecaptchaToken,
        recaptchaToken,
        postalCode,
        setPostalCode,
        name,
        setName,
        CPF,
        setCPF,
        checkoutProposal,
        setCheckoutProposal,
        loading,
        setLoading,
        idwallSteps,
        setIdwallSteps,
        getStatus,
        setGetStatus,
        disabledProgresIndicator,
        setDisabledProgresIndicator,
        defaultUserID,
        setDefaultUserID,
        handleMarketing,
        marketing,
        href,
        setHref,
        dataPrice,
        setDataPrice,
        saleTable,
        setSaleTable,
        preApproved,
        setPreApproved,
        proposalNumber,
        setProposalNumber,
        checkoutSteps,
        setCheckoutSteps,
        proposalAmounts,
        setProposalAmounts,
        birthday,
        setBirthday,
        handleDates,
        setGender,
        gender,
        assertiva,
        setAssertiva,
        HandleSteps,
        setSintegraError,
        sintegraError,
        ownerTyping,
        setOwnerTyping,
        challengeId,
        setChallengeId,
        openModalConfirmation,
        setOpenModalConfirmation,
        cellphone,
        email,
        setCellphone,
        setEmail,
        openAccountSuspect,
        setOpenAccountSuspect,
        backProposal,
        setBackProposal,
        openModalContactReason,
        setOpenModalContactReason
      }}
    >
      {children}
    </StepContext.Provider>
  )
}