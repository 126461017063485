import { split } from "../split"

export function transformValue(value: string) {
  const newValue = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  if (split(newValue, newValue.length - 2)[1] === '00') {
    return Number(split(newValue, newValue.length - 2)[0])
  } else {
    const array = split(newValue, newValue.length - 2)
    return Number(array[0] + '.' + array[1])
  }
}