import { createTheme } from "@mui/material"
import { green, orange, red } from "@mui/material/colors";

declare module '@mui/material/styles' {

  interface Palette {
    custom?: {
      warning?: string;
      success?: string;
      error?: string;
    } 
  }

  interface PaletteOptions {
    custom?: {
      warning?: string;
      success?: string;
      error?: string;
    } 
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#039be5',
    },
    secondary: {
      main: '#bbe54b',
    },
    common: {
      white: '#fff',
      black: '#343a40',
    },
    custom: {
      warning: orange[500],
      success: green[500],
      error: red[500]
    },
    
  }
})

export default theme