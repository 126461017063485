import { useContext, useEffect, useState } from 'react'
import { StepIndicator } from '../../stepIndicator'
import './styles.css'
import { useSnackbar } from 'notistack'
import { awaitTime } from '../../../utils/timing'
import Axios from 'axios'
import { StepContext } from '../../context'
export function ManualPhotos() {
    const { proposalToken, setLoading, setIdwallSteps } = useContext(StepContext)
    const { enqueueSnackbar } = useSnackbar()
    const [manualPhotosStep, setManualPhotosStep] = useState<number>(0)
    const [serious, setSerious] = useState<File | null>(null)
    const [smiling, setSmiling] = useState<File | null>(null)
    const [frontDoc, setFrontDoc] = useState<File | null>(null)
    const [backDoc, setBackDoc] = useState<File | null>(null)
    const [returnToReview, setReturnToReview] = useState<boolean>(false)

    const contentToRender = [
        {
            title: "FOTO DO ROSTO (NATURAL)",
            photoName: "face-serious",
            func: setSerious,
            value: serious,
        },
        {
            title: "FOTO DO ROSTO (SORRINDO)",
            photoName: "face-smiling",
            func: setSmiling,
            value: smiling,
        },
        {
            title: "FOTO DO SEU DOCUMENTO (FRENTE)",
            photoName: "front-doc",
            func: setFrontDoc,
            value: frontDoc,
        },
        {
            title: "FOTO DO SEU DOCUMENTO (VERSO)",
            photoName: "back-doc",
            func: setBackDoc,
            value: backDoc,
        }
    ]

    useEffect(() => {
        (async () => {
            const buttonElement = document.getElementById('button')
            /*@ts-ignore*/
            if (buttonElement) {
                await awaitTime(1)
                buttonElement.scrollIntoView({
                    block: "end",
                    behavior: "smooth",

                });
            }
        })()
        console.log('serious', serious)
        console.log('smiling', smiling)
        console.log('frontDoc', frontDoc)
        console.log('backDoc', backDoc)
    }, [serious, smiling, frontDoc, backDoc])

    const render = () => {
        switch (manualPhotosStep) {
            case 0:
                return (
                    <>
                        <img className='imgTutorial' src="https://front-source-assets.s3.sa-east-1.amazonaws.com/emprestimo/VAMOS+DAR+CONTINUIDADE+POR+AQUI.png" alt="Vamos dar continuidade por aqui" />
                        <button
                            className='buttonInitPhotos'
                            onClick={() => setManualPhotosStep(manualPhotosStep + 1)}
                        >Inserir as fotos</button>
                    </>
                )
            case 1:
                return (
                    <>
                        <p>Anexe abaixo a sua <br /> <strong>{contentToRender[manualPhotosStep - 1].title}</strong></p>
                        <label htmlFor="uploadPhoto" className='labelInput'>Clique aqui para tirar sua foto</label>
                        <input
                            id='uploadPhoto'
                            className='input'
                            type="file"
                            name={contentToRender[manualPhotosStep - 1].photoName}
                            accept="image/png, image/jpeg, image/jpg"
                            capture="user"
                            onChange={(e) => {
                                //@ts-ignore
                                if (e.target?.files[0]) {
                                    //@ts-ignore
                                    contentToRender[manualPhotosStep - 1].func(e.target?.files[0] as File)
                                }
                            }}
                        />
                        {contentToRender[manualPhotosStep - 1].value === null ?
                            <p>Aguardando foto...</p>
                            : <div className='previewImage'>
                                <p>Preview</p>
                                <img src={URL.createObjectURL(contentToRender[manualPhotosStep - 1].value as File)} />
                                <p>{contentToRender[manualPhotosStep - 1].value?.name}</p>
                            </div>}
                        {contentToRender[manualPhotosStep - 1].value !== null && <button
                            id="button"
                            className='buttonNextPhoto'
                            onClick={() => {
                                if (contentToRender[manualPhotosStep - 1].value !== null) {
                                    setManualPhotosStep(manualPhotosStep + 1)
                                } else {
                                    enqueueSnackbar(`Selecione a ${contentToRender[manualPhotosStep - 1].title} antes de continuar`, {
                                        variant: 'warning'
                                    })
                                }
                            }}
                        >Enviar {contentToRender[manualPhotosStep - 1].title}</button>}
                    </>
                )
            case 2:
                return (
                    <>
                        <p>Anexe abaixo a sua <br /> <strong>{contentToRender[manualPhotosStep - 1].title}</strong></p>
                        <label htmlFor="uploadPhoto" className='labelInput'>Clique aqui para tirar sua foto</label>
                        <input
                            id='uploadPhoto'
                            className='input'
                            type="file"
                            name={contentToRender[manualPhotosStep - 1].photoName}
                            accept="image/png, image/jpeg, image/jpg"
                            capture="user"
                            onChange={(e) => {
                                //@ts-ignore
                                if (e.target?.files[0]) {
                                    //@ts-ignore
                                    contentToRender[manualPhotosStep - 1].func(e.target?.files[0] as File)
                                }
                            }}
                        />
                        {contentToRender[manualPhotosStep - 1].value === null ?
                            <p>Aguardando foto...</p>
                            : <div className='previewImage'>
                                <p>Preview</p>
                                <img src={URL.createObjectURL(contentToRender[manualPhotosStep - 1].value as File)} />
                                <p>{contentToRender[manualPhotosStep - 1].value?.name}</p>
                            </div>}
                        {contentToRender[manualPhotosStep - 1].value !== null && <button
                            id="button"
                            className='buttonNextPhoto'
                            onClick={() => {
                                if (contentToRender[manualPhotosStep - 1].value !== null) {
                                    setManualPhotosStep(manualPhotosStep + 1)
                                } else {
                                    enqueueSnackbar(`Selecione a ${contentToRender[manualPhotosStep - 1].title} antes de continuar`, {
                                        variant: 'warning'
                                    })
                                }
                            }}
                        >Enviar {contentToRender[manualPhotosStep - 1].title}</button>}
                    </>
                )
            case 3:
                return (
                    <>
                        <p>Anexe abaixo a<br /> <strong>{contentToRender[manualPhotosStep - 1].title}</strong></p>
                        <label htmlFor="uploadPhoto" className='labelInput'>Clique aqui para tirar sua foto</label>
                        <input
                            id='uploadPhoto'
                            className='input'
                            type="file"
                            name={contentToRender[manualPhotosStep - 1].photoName}
                            accept="image/png, image/jpeg, image/jpg"
                            capture="environment"
                            onChange={(e) => {
                                //@ts-ignore
                                if (e.target?.files[0]) {
                                    //@ts-ignore
                                    contentToRender[manualPhotosStep - 1].func(e.target?.files[0] as File)
                                }
                            }}
                        />
                        {contentToRender[manualPhotosStep - 1].value === null ?
                            <p>Aguardando foto...</p>
                            : <div className='previewImage'>
                                <p>Preview</p>
                                <img src={URL.createObjectURL(contentToRender[manualPhotosStep - 1].value as File)} />
                                <p>{contentToRender[manualPhotosStep - 1].value?.name}</p>
                            </div>}
                        {contentToRender[manualPhotosStep - 1].value !== null && <button
                            id="button"
                            className='buttonNextPhoto'
                            onClick={() => {
                                if (contentToRender[manualPhotosStep - 1].value !== null) {
                                    setManualPhotosStep(manualPhotosStep + 1)
                                } else {
                                    enqueueSnackbar(`Selecione a ${contentToRender[manualPhotosStep - 1].title} antes de continuar`, {
                                        variant: 'warning'
                                    })
                                }
                            }}
                        >Enviar {contentToRender[manualPhotosStep - 1].title}</button>}
                    </>
                )
            case 4:
                return (
                    <>
                        <p>Anexe abaixo a <br /> <strong>{contentToRender[manualPhotosStep - 1].title}</strong></p>
                        <label htmlFor="uploadPhoto" className='labelInput'>Clique aqui para tirar sua foto</label>
                        <input
                            id='uploadPhoto'
                            className='input'
                            type="file"
                            name={contentToRender[manualPhotosStep - 1].photoName}
                            accept="image/png, image/jpeg, image/jpg"
                            capture="environment"
                            onChange={(e) => {
                                //@ts-ignore
                                if (e.target?.files[0]) {
                                    //@ts-ignore
                                    contentToRender[manualPhotosStep - 1].func(e.target?.files[0] as File)
                                }
                            }}
                        />
                        {contentToRender[manualPhotosStep - 1].value === null ?
                            <p>Aguardando foto...</p>
                            : <div className='previewImage'>
                                <p>Preview</p>
                                <img src={URL.createObjectURL(contentToRender[manualPhotosStep - 1].value as File)} />
                                <p>{contentToRender[manualPhotosStep - 1].value?.name}</p>
                            </div>}
                        {contentToRender[manualPhotosStep - 1].value !== null && <button
                            id="button"
                            className='buttonNextPhoto'
                            onClick={() => {
                                if (contentToRender[manualPhotosStep - 1].value !== null) {
                                    sendPhotos()
                                } else {
                                    enqueueSnackbar(`Selecione a ${contentToRender[manualPhotosStep - 1].title} antes de continuar`, {
                                        variant: 'warning'
                                    })
                                }
                            }}
                        >Enviar {contentToRender[manualPhotosStep - 1].title}</button>}
                    </>
                )
            default:
                break;
        }
    }

    const sendPhotos = async () => {
        setLoading(true)
        const data = new FormData()
        for (let photo of contentToRender) {
            data.append(photo.photoName, photo.value as File)
        }
        await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals/upload/faceid`, data, {
            headers: {
                'Content-Type': 'multipart/formdata',
                'x-token': proposalToken
            }
        }).then(() => {
            setIdwallSteps(3)
        }).catch((err) => {
            enqueueSnackbar("Erro ao enviar fotos para análise", {
                variant: 'error'
            })
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <div className="container" id="containerPhotos">
            <StepIndicator />
            {render()}
        </div>
    )
}