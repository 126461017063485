import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { InputHTMLAttributes, } from "react";
import './styles.css'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  mask?: string;
  autoDisabled?: boolean;
  icon?: ReactJSXElement;
  haveNumber?: boolean;
}

export function InputComponent({ label, autoDisabled = false, icon, haveNumber = false, ...rest }: InputProps) {
  return (
    <div className="containerInput">
      {icon ?
        <div className="withICon">
          <label htmlFor="input">{label}</label>
          {icon}
        </div> :

        <label htmlFor="input">{label}</label>
      }

      <input className="input" {...rest} disabled={autoDisabled} style={{ fontFamily: rest.type === 'tel' || rest.type === 'date' || rest.type === 'email' || haveNumber? "Poppins" : "inherit"}} />

    </div>
  )
}