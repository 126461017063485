import { cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';
import { formatDateOnly } from '../../../../utils/formatDate';
import googleLibphone from 'google-libphonenumber';


const minDataBirthday = new Date(new Date().getTime() - (86400000 * ((18 * 365) + 4)))

const phoneUtil = googleLibphone.PhoneNumberUtil.getInstance();

const validateNumber = (number: string | undefined) => {
  try {
    const parserNumber = phoneUtil.parseAndKeepRawInput(number as string, 'BR');
    const validationPhone = phoneUtil.isValidNumberForRegion(
      parserNumber,
      'BR',
    );

    if (!validationPhone) {
      return false;
    }

    return parserNumber;
  } catch (err) {
    return false;
  }
};

const validateCellphone = (number: string | undefined) => {
  const validNumber = validateNumber(number);

  if (!validNumber) {
    return false;
  }

  const typeNumber = phoneUtil.getNumberType(validNumber);

  if (typeNumber !== 1) {
    return false;
  }

  return true;
};

const stepVerify = yup.object().shape({
  CPF: yup
    .string()
    .test('validateCPF', 'Informe seu CPF corretamente;', (value) => cpf.isValid(value as string))
    .required('Informe seu CPF corretamente;'),

  birthday: yup
    .date()
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .max(
      minDataBirthday,
      `Data de nascimento deve ser igual ou inferior a ${formatDateOnly(minDataBirthday)
      };`,
    )
    .required('Informe sua data de nascimento corretamente;'),
  CEP: yup
    .string()
    .matches(/^[\d]{5}-[\d]{3}$/, 'Digite seu atual CEP;')
    .required('Digite seu atual CEP;'),
  email: yup
    .string()
    .email('Informe um email válido. Ex.: exemplo@exemplo.com;')
    .required('Informe seu melhor e-mail;'),

  cellphone: yup
    .string()
    .test(
      'validateBRCellphone',
      'Informe seu whatsapp;',
      validateCellphone,
    )
    .required('Informe seu whatsapp;'),
  contactReason: yup
  .mixed()
  .oneOf(
    [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8'
    ], 'Informe o motivo da contratação;')
}).required('Informe o motivo da contratação;')

export { stepVerify }