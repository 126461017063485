import './styles.css'
import GearImage from '../../assets/gear.png'
import ImgLoading from '../../../src/assets/Img loading.jpg'

export const Maintenance = () => {
  return (
    <div className="containerMaintenance">
      <img src={ImgLoading} alt='Melhorias imagem' style={{
        width: '100%',
        maxWidth: '700px',
        padding: '0px 20px'
      }}/>
      {/* <p style={{color: "red"}}>AVISO DE ATUALIZAÇÃO!</p>
      <p>Cliente, para melhor experiência do cliente e maior produtividade estaremos em manutenção técnica até 06/05/2024, período de  implementação para pagamentos dos empréstimos via PIX.
      </p>
      <div className="gearImages">
        <img src={GearImage} alt="GearImage" />
        <img src={GearImage} alt="GearImage" />
      </div>
      <br /> */}
      {/* <p>Solicitamos que acesse novamente amanhã!</p> */}
    </div>
  )
}