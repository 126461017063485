
import { useContext, useEffect, useState } from 'react'
import avatar from '../../../assets/fotos.svg'
import logo from '../../../assets/BOLA TROCA_CRÉDITOS.png'
import { StepIndicator } from "../../stepIndicator"
import { StepContext } from '../../context'
import './styles.css'
import { awaitTime } from '../../../utils/timing'
import Axios from 'axios'
import { OperationError } from '../../operationError'
import { Tarja } from '../../tarja'
import { Dialog } from '@mui/material'
import { ManualPhotos } from '../manualPhotos'
import { io } from 'socket.io-client'


export function Idwall() {
  const { proposalToken, setStep, step, progressIndicator, setProgressIndicator, loading, setLoading, idwallSteps, setIdwallSteps, getStatus, setGetStatus, setOperationSuccess, assertiva, ownerTyping, setAssertiva, HandleSteps, setDisabledProgresIndicator, proposalNumber } = useContext(StepContext)
  const [openDialogPhotos, setOpenDialogPhotos] = useState(false)
  useEffect(() => {

    (async () => {
      if (getStatus === true) {
        await awaitTime(5)
        setGetStatus(false)
        getFaceIDStatus()
      }
    })()

  }, [getStatus])

  useEffect(() => {
    (async () => {
      await awaitTime(1.1)
      sessionStorage.setItem('tc-idwallSteps', idwallSteps.toString())
    })()
  }, [idwallSteps])

  useEffect(() => {
    (async () => {
      await Axios.get(`https://api.assertivasolucoes.com.br/autentica/v1/jornadas/coletas/fluxo-token?id=${assertiva.split('.io/')[1]}`).catch((err) => {
        if (err?.response?.data?.success === false) {
          setAssertiva('')
        }
      })
      await awaitTime(2)
      setDisabledProgresIndicator(true)
    })()
  }, [])

  useEffect(() => {
    if (proposalNumber) {
      const socket = io(process.env.REACT_APP_ONLINE_WS as string);
      socket.on(proposalNumber?.toString(), (...args) => {
        console.log(args)
        if (args[0] === "faceid_aproved") {
          getFaceIDStatus()
        }
      });
    }
  }, [proposalNumber])



  useEffect(() => {
    (async () => {
      setLoading(true)
      await awaitTime(2)

      const sessionIdwallSteps = sessionStorage.getItem('tc-idwallSteps')
      const sessionIdwallToken = sessionStorage.getItem('tc-idwallToken')
      if (sessionIdwallToken) {
        setGetStatus(true)
      }
      setIdwallSteps(Number(sessionIdwallSteps) < 3 ? 1 : Number(sessionIdwallSteps))

      setLoading(false)
    })()

  }, [])
  useEffect(() => {
    if (idwallSteps === 2 || idwallSteps === 3) {
      setGetStatus(true)
    }
  }, [idwallSteps])

  const getFaceIDStatus = async () => {
    await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': proposalToken,
      }
    }).then(({ data }) => {
      if (data.proposal.faceid_status === "confirmed") {
        setStep(4)
        setProgressIndicator(progressIndicator + 1)
        setGetStatus(true)
        setOperationSuccess(true)
        HandleSteps(5)
      } else if (data.proposal.faceid_status === 'failed') {
        setGetStatus(false)
        setIdwallSteps(4)
      } else {
        setGetStatus(true)
      }
    })
  }


  const getPermission = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
  }

  const openDialog = async () => {
    await awaitTime(45)
    setOpenDialogPhotos(true)
  }

  const render = () => {
    switch (idwallSteps) {
      case 1:
        return (
          <>
            <StepIndicator />

            <div className="container">
              <img src={avatar} />
              <p><strong>Para a segurança de todos, </strong>iremos dar inicio as fotos:</p>
              <ul>
                <li><strong>Sua selfie natural</strong></li>
                <li><strong>Sua selfie sorrindo</strong></li>
                <li><strong>Frente do documento</strong></li>
                <li><strong>Verso do documento</strong></li>
              </ul>
              <p style={{ color: 'red', fontSize: '14px' }}><p style={{ color: 'red', fontSize: '18px', padding: 0, margin: 0, marginBottom: '-20px' }}>PERMITA O ACESSO À SUA CÂMERA </p>
                <br />assim que solicitado!</p>

              <button onClick={async () => {
                if (assertiva) {
                  setIdwallSteps(2)
                  getPermission()
                  openDialog()
                } else {
                  setIdwallSteps(5)
                }
              }} className="buttonContinue defaultMarginBottom" id="remove">Continuar</button>
              <p style={{ fontSize: '16px', marginTop: '-20px' }}>Obs: Caso não consiga formalizar suas fotos por aqui, <u>sugerimos que <strong>envie o mesmo link que recebeu por WhatsApp</strong> para outro celular, assim poderá capturar suas fotos com sucesso.</u></p>
              <Tarja />
            </div>
          </>
        )
      case 2:
        return (
          <div style={{ width: '100vw', height: '100vh' }}>
            <iframe src={assertiva as string} style={{ width: '100%', height: '100%' }} allow={`camera ${assertiva}`}></iframe>
          </div>
        )
      case 3:
        return (
          <div className='containerWaiting'>
            <StepIndicator />
            <div className="waiting">
              <p>Aguarde um instante por favor, estamos analisando suas fotos e seu documento.<br /><br />Tempo estimado: <br />3 minutos a 1 hora útil
              </p>
              <p>Caso encerre a conexão, clique no link que enviamos para seu <strong>Whatsapp</strong> e continue de onde você parou.</p>
              <img src={logo} alt="logo" />
            </div>
            <Tarja />
          </div>

        )
      case 4:
        return (
          <div className="ContainerDontHaveCard">
            <OperationError />
            <div className="title">
              <p>Incosistências em suas fotos!</p>
            </div>
            <div className="description" style={{ display: 'flex', flexDirection: 'column' }}>
              <p>Solicitamos que inicie novamente o envio das imagens corretamente.
              </p>
              <button
                style={{ alignSelf: 'center' }}
                className='buttonCardActive'
                onClick={() => setIdwallSteps(5)}
              >Entendi</button>
            </div>
          </div>
        )
      case 5:
        return <ManualPhotos />
    }
  }


  return (
    <div className='containerFaceID'>
      <Dialog open={openDialogPhotos} className='dialogPhotos'>
        <p>Está conseguindo enviar as fotos?</p>
        <button
          onClick={() => setOpenDialogPhotos(false)}
        >Sim, estou conseguindo</button>
        <button
          onClick={() => {
            setOpenDialogPhotos(false)
            setIdwallSteps(5)
          }}
        >Não estou conseguindo enviar as fotos</button>
      </Dialog>
      {idwallSteps === 2 && <StepIndicator />}
      {render()}
    </div>
  )
}