import { CssBaseline, ThemeProvider } from '@mui/material'
import { ContextProvider } from './components/context';
import { Routes } from './components/routes';
import theme from './components/theme'
import { SnackbarProvider } from 'notistack'
import './App.css'
import './fonts/Cocogoose Pro Light-trial.ttf'

function App() {
  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <SnackbarProvider
            className='snack'
            autoHideDuration={5000}
            dense={true}
          >
            <Routes />
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </ContextProvider>
  )
}
export default App
