import { SelectHTMLAttributes } from "react";
import './styles.css'

export interface GenderOptionsProps {
  label: string;
  value: string
}

export interface StateOptionsProps {
  sigla: string;
  nome: string;
  cidades: Array<string>;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  isDialog?: boolean;
  genderOptions?: GenderOptionsProps[];
  stateOptions?: StateOptionsProps[];
  cityOptions?: Array<string>;
  bankOptions?: bankOptionsProps[];
}

interface bankOptionsProps {
  code: string;
  name: string;
}

export function SelectComponent({ label, genderOptions, stateOptions, cityOptions, bankOptions, name, isDialog, ...rest }: SelectProps) {
  return (
    <div className={isDialog ? "containerInputDialog" : "containerInput"}>
      <label htmlFor="input" className={name === "gender" ? "genderLabel" : "defaultLabel"}>{label}</label>
      <select defaultValue={"Selecione"} {...rest} style={{ fontFamily: bankOptions ? 'Poppins' : 'inherit' }}>
        <option value={"Selecione"} >Selecione...</option>
        {genderOptions ?
          <>
            {genderOptions?.map((item) => {
              return (
                <option key={item.value} value={item.value}>{item.label}</option>
              )
            })}
          </> :
          cityOptions ?
            <>
              {cityOptions?.map((item) => {
                return (
                  <option key={item} value={item}>{item}</option>
                )
              })}
            </>

            :
            stateOptions ?
              <>
                {stateOptions?.map((item) => {
                  return (
                    <option key={item.sigla} value={item.sigla}>{item.sigla} - {item.nome}</option>
                  )
                })}
              </>
              :
              <>
                {bankOptions?.map((item) => {
                  return (
                    <option style={{ fontFamily: 'Poppins' }} value={item.name} key={item.code}>{item.code} - {item.name}</option>
                  )
                })}
              </>

        }
      </select>
    </div>
  )
}