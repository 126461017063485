import { CircularProgress, Dialog } from '@mui/material'
import './styles.css'
export function Loading({ active }: { active: boolean }) {
  
  return (
    <>
    <Dialog open={active} className="loading">
      <CircularProgress size={60}/>
    </Dialog>
    </>
  )
}