import moment from 'moment';

const formatDateOnly = (date: Date) => {
  const dateToFormat = moment(date);
  return dateToFormat.format('DD/MM/YYYY');
};

const formatDateWithTime = (date: Date) => {
  const dateToFormat = moment(date);
  return dateToFormat.format('DD/MM/YYYY HH:mm:ss');
};

const dataBR = (newData: string) => {
  let data = new Date(newData);
  let day = (data.getDate() + 1).toString().padStart(2, '0')
  let month = (data.getMonth() + 1).toString().padStart(2, '0')
  let year = data.getFullYear()


  return day + "/" + month + "/" + year;
}

const toAAAMMDD = (data: string) => {
  const split = data.split('/')
  const result = split[2] + '/' + split[1] + '/' + split[0]
  return new Date(result)
}

const toAAAMMDDString = (data: string) => {
  const split = data.split('/')
  const result = split[2] + '/' + split[1] + '/' + split[0]
  return result
}

export { formatDateWithTime, formatDateOnly, dataBR, toAAAMMDD, toAAAMMDDString };