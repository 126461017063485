import * as yup from 'yup';

const cardValidator = yup.object().shape({
  name: yup
    .string()
    .min(
      4,
      'Digite seu nome completo que consta em seu cartão de crédito;',
    )
    .required('Digite seu nome completo que consta em seu cartão de crédito;'),

  number: yup
    .string()
    .trim()
    .min(15, 'Digite todos os números do seu cartão;')
    .required('Digite todos os números do seu cartão;'),

  expiry: yup
    .string()
    .trim()
    .min(4, 'Digite o mês e ano de expiração do seu cartão;')
    .required('Digite o mês e ano de expiração do seu cartão;'),

  cvc: yup
  .string()
  .trim()
  .min(3, 'Digite o CVV do seu cartão;')
  .required('Digite o CVV do seu cartão;'),
  
})


export { cardValidator };