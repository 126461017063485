import { ChangeEvent, useContext, useEffect, useState } from "react";


import estadosCidades from '../../../assets/state_city.json'
import banks from '../../../assets/conta_bancaria.json'

import 'moment/locale/pt-br';

import Axios, { CancelTokenSource } from 'axios'
import ReCAPTCHA from 'react-google-recaptcha';
import { useSnackbar } from "notistack";
import { StepContext } from "../../context";
import { CheckoutChargeAddress, CheckoutInterface, clientProps, IIValidatorDataBanks, ProposalProps, SessionRegister1Props, SessionRegister2Props, SessionRegister3Props } from '../../@interfaces'
import { step1, step2, step3, step4 } from "./validator";
import { StepIndicator } from "../../stepIndicator";
import './styles.css'
import { InputComponent } from "../../form/input";
import { GenderOptionsProps } from '../../form/select'
import { SelectComponent } from "../../form/select";
import { formatinputs } from "../../../utils/formatCpf";
import { InputMaskComponent } from "../../form/inputMask";
import { awaitTime } from "../../../utils/timing";
import { Dialog, Grid, Typography } from "@mui/material";
import { formatAccount } from "../../../utils/formatAccount";
import { toAAAMMDD, toAAAMMDDString } from "../../../utils/formatDate";
import { Tarja } from "../../tarja";

import chatImage from '../../../assets/animated.gif';
import axios from "axios";
import { ErrorOutline } from "@mui/icons-material";

export function Register() {
    const { enqueueSnackbar } = useSnackbar()
    const {
        setStep,
        proposal,
        linkId,
        registerSteps,
        setRegisterSteps,
        progressIndicator,
        setProgressIndicator,
        proposalToken,
        setProposalToken,
        recaptchaToken,
        setRecaptchaToken,
        postalCode,
        name,
        setName,
        CPF,
        setLoading,
        setCheckoutProposal,
        setGetStatus,
        setCheckoutSteps,
        birthday,
        setBirthday,
        gender,
        setGender,
        sintegraError,
        openModalConfirmation,
        cellphone,
        email,
        setCellphone,
        setEmail,
        HandleSteps,
        setOpenModalConfirmation,
        setOpenModalContactReason,
        openModalContactReason,
        proposalNumber
    } = useContext(StepContext)



    const [birthState, setBirthState] = useState<string>('');

    const [birthCity, setBirthCity] = useState('');


    const [motherName, setMotherName] = useState('');

    const [rg, setRg] = useState('');

    const [issuingAgency, setIssuingAgency] = useState('');

    const [issueDate, setIssueDate] = useState<string>('');

    const [address, setAddress] = useState('');
    const [statusAddress, setStatusAddress] = useState('');

    const [addressNumber, setAddressNumber] = useState('');

    const [district, setDistrict] = useState('');
    const [statusDistrict, setStatusDistrict] = useState('');

    const [addressComplement, setAddressComplement] = useState('');

    const [CEP, setCEP] = useState('');

    const [state, setState] = useState<string>('');
    const [statusState, setStatusState] = useState('');

    const [city, setCity] = useState('');
    const [statusCity, setStatusCity] = useState('');

    const genderOptions: GenderOptionsProps[] = [
        {
            label: "Masculino",
            value: "M"
        },
        {
            label: "Feminino",
            value: "F"
        },
    ]

    const accountTypes: GenderOptionsProps[] = [
        {
            label: "Conta Corrente",
            value: "CONTA_CORRENTE"
        },
        {
            label: "Conta Poupança",
            value: "CONTA_POUPANCA"
        },
    ]
    const [cityOptions, setCityOptions] = useState<Array<string>>([])

    const [bank, setBank] = useState<string>('')
    const [agency, setAgency] = useState<string>('')
    const [account, setAccount] = useState<string>('')
    const [accountType, setAccountType] = useState<string>('')

    const [errorList, setErrorList] = useState<{ message: string; path: string }[]>([])
    const [openFormError, setOpenFormError] = useState<boolean>(false)
    const [adressInputsDisabled, setAddresInputsDisabled] = useState<boolean>(false)
    const [defaultDisabled, setDefaultDisabled] = useState<boolean>(false) //true
    const [nameDisabled, setNameDisabled] = useState<boolean>(false)
    const [openDialogAccount, setOpenDialogAccount] = useState<boolean>(false)

    const [openSintegraAlert, setOpenSintegraAlert] = useState<boolean>(false)

    const [openFormErrorTransfeera, setOpenFormErrorTransfeera] = useState(false)

    const [dialogTransfeeraAproved, setDialogTransfeeraAproved] = useState(false)
    const [reservedToken, setReservedToken] = useState('')

    const [contactReason, setContactReason] = useState("")


    useEffect(() => {
        if (birthCity) {
            setBirthCity('')
        }
        const newCities = estadosCidades.find((item) => item.sigla === birthState)
        setCityOptions(newCities?.cidades as Array<string>)
    }, [birthState])

    // useEffect(() => {
    //   (async () => {
    //     if (getStatus === true) {
    //       await awaitTime(5)
    //       setGetStatus(false)
    //       getFaceIDStatus()
    //     }
    //   })()

    // }, [getStatus])

    // useEffect(() => {
    //   sessionStorage.setItem('tc-defaultDisabled', defaultDisabled.toString())
    // }, [defaultDisabled])

    useEffect(() => {
        if (postalCode === '') {
            if (city && formatinputs.toString(CEP).length) {
                setCity('')
            }
        }
        const newCities = estadosCidades.find((item) => item.sigla === state)
        setCityOptions(newCities?.cidades as Array<string>)
    }, [state])

    useEffect(() => {
        (async () => {
            if (registerSteps === 2) {
                if (postalCode !== '') {
                    await awaitTime(2)
                    setAddresInputsDisabled(true)
                    getCep(postalCode)
                    setCEP(postalCode)
                }
            }
        })()
    }, [registerSteps])

    useEffect(() => {
        const initElement = document.getElementById('init')
        /*@ts-ignore*/
        if (initElement) {
            initElement.scrollIntoView({
                block: "end",
                behavior: "smooth"
            });
        }
        // const sessiondefaultDisabled = sessionStorage.getItem("tc-defaultDisabled")
        // setDefaultDisabled(Boolean(sessiondefaultDisabled))



        const sessionRegister1 = sessionStorage.getItem('sessionRegister1')
        if (sessionRegister1) {
            const data: SessionRegister1Props = JSON.parse(sessionRegister1)
            setBirthday(data.birthday)
            setName(data.name)
            setGender(data.gender)
            setRg(data.rg)
            setIssuingAgency(data.issuingAgency)
            setIssueDate(data.issueDate)
            setBirthState(data.birthState)
            setMotherName(data.motherName)

            setTimeout(() => {
                setBirthCity(data.birthCity)
            }, 100);
        }

        const sessionRegister2 = sessionStorage.getItem('sessionRegister2')
        if (sessionRegister2) {
            const data: SessionRegister2Props = JSON.parse(sessionRegister2)
            setEmail(data.email)
            setCellphone(data.cellphone)
            setCEP(data.CEP)
            setState(data.state)
            setCity(data.city)
            setAddress(data.address)
            setAddressNumber(data.addressNumber)
            setAddressComplement(data.addressComplement)
            setDistrict(data.district)
            setAccount(data.account)
            setAccountType(data.account_type)
            setBank(data.bank)
            setAgency(data.agency)
            setBirthday(birthday)
            setIssueDate(data.issueDate)
            setIssuingAgency(data.issuingAgency)
            setName(data.name)
            setRg(data.rg)
        }

        const sessionRegister3 = sessionStorage.getItem('sessionRegister3')
        if (sessionRegister3) {
            const data: SessionRegister3Props = JSON.parse(sessionRegister3)
            setBank(data.bank)
            setAgency(data.agency)
            setAccount(data.account)
            setAccountType(data.account_type)
        }
    }, [])


    const handleDates = (e: ChangeEvent<any>, setFunction: any, inputId: string) => {

        const value = e.target.value
        const [day, month, year] = [value.split('/')[0], value.split('/')[1], value.split('/')[2]]
        const [day1, day2, month1, month2] = [day.split('')[0], day.split('')[1], month.split('')[0], month.split('')[1]]
        const input = document.getElementById(inputId)
        // dia 1 entre 0 & 3
        if (day1 !== '_') {
            if (Number(day1) > 3) {
                setFunction(`3_/__/____`)
                window.setTimeout(function () {
                    /*@ts-ignore*/
                    input.setSelectionRange(1, 1);
                }, 0);
            } else {
                setFunction(value)
            }
        } else {
            setFunction(value)
        }
        //dia 2: se dia 01 for igual a 0 e dia 01 for igual a 0, logo day será 01; se dia 1 for 3 e dia 2 for maior que 1, logo dia será 31
        if (day2 !== '_') {
            if (day1 === '3') {
                if (Number(day2) > 1) {
                    setFunction('31/__/____')
                } else {
                    setFunction(value)
                }
            }

            if (day1 === '0') {
                if (day2 === '0') {
                    setFunction(`01/__/____`)
                    window.setTimeout(function () {
                        /*@ts-ignore*/
                        input.setSelectionRange(3, 3);
                    }, 0);
                } else {
                    setFunction(value)
                }
            }


        }
        // mes entre 01 e 12

        if (month1 !== '_') {
            if (Number(month1) > 1) {
                setFunction(`${day1}${day2}/0${month1}/____`)
                window.setTimeout(function () {
                    /*@ts-ignore*/
                    input.setSelectionRange(6, 6);
                }, 0);
            }
            if (Number(month1) === 1) {
                if (month2 > 2) {
                    setFunction(`${day1}${day2}/12/____`)
                    window.setTimeout(function () {
                        /*@ts-ignore*/
                        input.setSelectionRange(6, 6);
                    }, 0);
                } else {
                    setFunction(value)
                }
            }
            if (Number(month1) === 0) {
                if (Number(month2) === 0) {
                    setFunction(`${day1}${day2}/01/____`)
                    window.setTimeout(function () {
                        /*@ts-ignore*/
                        input.setSelectionRange(6, 6);
                    }, 0);
                    return
                } else {
                    setFunction(value)
                }
            }
        }
    }

    function getCep(cep: string) {
        const formatCep = formatinputs.toString(cep)
        Axios.get(`https://viacep.com.br/ws/${formatCep}/json/`).then(async ({ data }) => {
            if (data.erro) {
                setAddresInputsDisabled(false)
                setState('')
                setCity('')
                return
            }
            // setAddress(data.logradouro)
            // setDistrict(data.bairro)

            setAddresInputsDisabled(true)
            setState(data.uf)
            await awaitTime(0.1)
            setCity(data.localidade)
            // setAddressComplement(data.complemento)

            if (statusState) setStatusState('')
            if (statusCity) setStatusCity('')
            if (statusDistrict) setStatusDistrict('')
            if (statusAddress) setStatusAddress('')

        }).catch(() => {
            setAddresInputsDisabled(false)
            setState('')
            setCity('')
        })
    }

    async function clientRegister() {

        setLoading(true)
        await awaitTime(0.4)

        const data: clientProps = {
            name: name.trim(),
            email: email,
            cellphone: formatinputs.toString(cellphone),
            cpf: formatinputs.toString(CPF),
            id_document: rg,
            id_document_issue_date: toAAAMMDDString(issueDate),
            id_document_issuing_agency: issuingAgency,
            birthday: toAAAMMDDString(birthday),
            address: {
                street: address,
                number: addressNumber,
                district: district,
                city: city,
                state: state,
                cep: formatinputs.toString(CEP),
            },
            gender: gender
        }

        const newProposal: ProposalProps = {
            client: data,
            credit_card_limit: proposal?.credit_card_limit,
            link_id: linkId,
            net_amount: proposal?.net_amount,
            parcels: proposal?.parcels,
        }

        const CancelToken = Axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        // cancelRequest(cancelTokenSource)

        await Axios.post(`${process.env.REACT_APP_ONLINE_API}/proposals`, newProposal, {
            headers: {
                'Content-Type': 'application/json',
                'x-recaptcha-token': recaptchaToken,
            },
            cancelToken: cancelTokenSource.token
        }).then(async ({ data }) => {
            const chargeAddress: CheckoutChargeAddress = {}
            chargeAddress.cep = formatinputs.toString(CEP)
            chargeAddress.city = city
            chargeAddress.district = district
            chargeAddress.number = addressNumber
            chargeAddress.street = address
            chargeAddress.state = state

            const newData: CheckoutInterface = {
                checkout: {
                    chargeAddress: chargeAddress,
                    clientCpf: formatinputs.toString(CPF),
                }
            }

            setCheckoutProposal(newData)
            setReservedToken(data.proposalToken)
            // setDialogTransfeeraAproved(true)
            setProposalToken(data.proposalToken)
            setOpenModalConfirmation(true)
        }).catch((error) => {

            if (axios.isCancel(error)) {
                return clientRegister()
            }
            if (error.response.data.point === "score" || error.response.data.point === "insight") {
                setRegisterSteps(5)
                return
            } else if (error.response.data.point === "transfeera") {
                setOpenFormErrorTransfeera(true)
            } else {
                enqueueSnackbar(error.response?.data?.message ?? "Falha ao criar proposta!", {
                    variant: 'error'
                })
            }
            setRecaptchaToken(null)
            /*@ts-ignore*/
            window.grecaptcha.reset()
        }).finally(() => {
            setLoading(false)
            HandleSteps(2)
        })

    }

    async function cancelRequest(cancelToken: CancelTokenSource) {
        await awaitTime(5)
        cancelToken.cancel('')
    }


    async function getAccount(id: string) {
        await awaitTime(2)
        return Axios.get(`${process.env.REACT_APP_ONLINE_API}/validate/consult/${id}`).then(({ data }) => {
            if (data.micro_deposit_status === "VALIDADO") {
                return true
            } else {
                if (data.errors.length === 0) {
                    return []
                }
                // const array: { message: string; path: string }[] = []
                // data.errors.forEach((err: any) => {
                //   let errValue: { message: string; path: string } = {
                //     message: err.message,
                //     path: err.field
                //   }
                //   array.push(errValue)
                // });
                // setErrorList(array)
                setOpenFormErrorTransfeera(true)
                setLoading(false)
                return false
            }
        })
    }

    const nextRegisterSteps = async () => {

        const actuallyStep = registerSteps === 1 ? step1 : registerSteps === 2 ? step2 : registerSteps === 3 ? step3 : step4
        await actuallyStep.validate({
            name,
            CPF,
            rg,
            issuingAgency,
            issueDate: formatinputs.toString(issueDate).length === 8 && toAAAMMDD(issueDate) as string | Date !== 'Invalid Date' ? toAAAMMDD(issueDate) : null,
            birthday: formatinputs.toString(birthday).length === 8 && toAAAMMDD(birthday) as string | Date !== 'Invalid Date' ? toAAAMMDD(birthday) : null,
            gender,
            // birthState,
            // birthCity,
            // motherName,
            email,
            cellphone,
            CEP: CEP.replace('.', ''),
            state,
            city,
            district,
            address,
            addressNumber,
            bank,
            agency_number: agency,
            account,
            // account_digit: accountDigit,
            account_type: accountType,
            recaptchaToken,
            sintegraError
        }, { abortEarly: false }).then(() => {

            if (registerSteps === 2) {
                const sessionRegister2: SessionRegister2Props = {
                    address,
                    addressComplement,
                    addressNumber,
                    cellphone,
                    CEP,
                    city,
                    district,
                    email,
                    state,
                    account,
                    account_type: accountType,
                    agency,
                    bank,
                    birthday: birthday,
                    issueDate: issueDate,
                    issuingAgency: issuingAgency,
                    name: name,
                    rg: rg
                }
                sessionStorage.setItem('sessionRegister2', JSON.stringify(sessionRegister2))
                // const sessionRegister3: SessionRegister3Props = {
                //   account,
                //   agency,
                //   bank,
                //   account_type: accountType
                // }
                // sessionStorage.setItem('sessionRegister3', JSON.stringify(sessionRegister3))
                clientRegister()
            } else if (registerSteps === 6) {
                sendToClearSale()
            } else if (registerSteps === 3) {
                // sendToTransfeera()
            } else {
                switch (registerSteps) {
                    case 1:
                        const sessionRegister1: SessionRegister1Props = {
                            birthCity,
                            birthday,
                            birthState,
                            gender: gender as string,
                            issueDate,
                            issuingAgency,
                            motherName,
                            name,
                            rg
                        }
                        sessionStorage.setItem('sessionRegister1', JSON.stringify(sessionRegister1))
                        break;
                    case 2:
                        const sessionRegister2: SessionRegister2Props = {
                            address,
                            addressComplement,
                            addressNumber,
                            cellphone,
                            CEP,
                            city,
                            district,
                            email,
                            state,
                            account,
                            account_type: accountType,
                            agency,
                            bank,
                            birthday: birthday,
                            issueDate: issueDate,
                            issuingAgency: issuingAgency,
                            name: name,
                            rg: rg
                        }
                        sessionStorage.setItem('sessionRegister2', JSON.stringify(sessionRegister2))
                        break;

                    default:
                        break;
                }
                setRegisterSteps(registerSteps + 1)
                setProgressIndicator(progressIndicator + 1)
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }, 100);
            }

        }).catch(async (err) => {
            const array: { message: string; path: string }[] = []
            await err.inner.forEach((element: any) => {

                if (array.filter(e => e.path === element.path).length === 0) {
                    array.push({ message: element.message, path: element.path })
                }
            })
            setErrorList(array)
            setOpenFormError(true)
        })
    }



    const getFaceIDStatus = async () => {
        await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
            headers: {
                'Content-Type': 'application/json',
                'x-token': proposalToken,
            }
        }).then(({ data }) => {
            if (data.proposal.proposal_status === "started") {
                // accountUpdate(true)
                setGetStatus(false)
            } else {
                setGetStatus(true)
            }
        }).catch(() => {
            setGetStatus(true)
        })
    }

    const sendToClearSale = async () => {
        setLoading(true)
        const data = {
            client: {
                gender: gender,
                id_document: formatinputs.toString(rg),
                id_document_issue_date: toAAAMMDDString(issueDate),
                id_document_issuing_agency: issuingAgency
            }
        }
        await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals`, data, {
            headers: {
                "x-token": proposalToken
            }
        }).then(({ data }) => {
            setRegisterSteps(3)
            setProgressIndicator(progressIndicator + 1)
        }).catch((error) => {
            if (error.response.data.point === "score" || error.response.data.point === "insight") {
                setRegisterSteps(5)
                return
            } else {
                enqueueSnackbar("Falha ao criar proposta!", {
                    variant: 'error'
                })
            }
        }).finally(() => setLoading(false))
    }

    const handleContactReason = async () => {
        if (contactReason === "") {
            enqueueSnackbar("Selecione o motivo da contratação", {
                variant: 'warning'
            })
            return
        }
        await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals/contact-reason/${proposalNumber}`, {
            value: contactReason
        }, {
            headers: {
                "x-token": proposalToken
            }
        }).then(({ data }) => {
            console.log(data)
            setCheckoutSteps(4)
            setStep(3)
            setProgressIndicator(3)
        }).catch((error) => {
            enqueueSnackbar("Erro ao informar motivo da contratação", {
                variant: 'error'
            })
        })
    }



    const render = () => {
        switch (registerSteps) {
            case 1:
                return (
                    <>
                        <StepIndicator />
                        <p className="title">Solicitamos que <strong>digite seus dados pessoais</strong> corretamente.</p>
                        <div className="info">
                            <p><strong>"Seus dados pessoais estão sob sigilo</strong> e serão tratados pela empresa <strong>Assertiva,</strong> em
                                conformidade com a lei 13.709/2018, Lei Geral de Proteção de Dados - LGPD"</p>
                        </div>
                        <div className="containerTitle">
                            <strong>Informações pessoais</strong>
                            <div />
                        </div>
                        <InputMaskComponent id="birthday" label="Sua data de nascimento" mask="99/99/9999" type="tel" placeholder="DD/MM/AAAA" value={birthday} onChange={(e) => handleDates(e, setBirthday, 'birthday')} />
                        <InputComponent label="Seu nome completo" type="text" placeholder="Digite aqui..." autoDisabled={nameDisabled} value={name} onChange={(e) => setName(e.target.value)} />

                        {/* <SelectComponent label="Estado de nascimento (UF)" stateOptions={estadosCidades} value={birthState} onChange={(e) => setBirthState(e.target.value)} disabled={defaultDisabled} />
            <SelectComponent label="Sua cidade de nascimento" cityOptions={cityOptions} value={birthCity} onChange={(e) => setBirthCity(e.target.value)} disabled={defaultDisabled} /> */}
                        {/* <InputComponent label="Nome completo da mãe" type="text" placeholder="Digite aqui..." value={motherName} onChange={(e) => setMotherName(e.target.value)} autoDisabled={defaultDisabled} /> */}

                        <button className="buttonContinue" onClick={nextRegisterSteps}>Continuar</button>
                        <Tarja />

                    </>
                )
            case 2:
                return (
                    <>
                        <StepIndicator />
                        <p className="title" id="init">Solicitamos que <strong>digite seus dados pessoais</strong> corretamente.</p>
                        {/* <div className="containerTitle">
              <strong>Cadastro</strong>
              <div />
            </div> */}
                        {sintegraError &&
                            <>
                                <InputComponent label="Seu nome completo" type="text" placeholder="Digite aqui..." autoDisabled={nameDisabled} value={name} onChange={(e) => setName(e.target.value)} />
                                <SelectComponent label="Gênero (Conforme Receita Federal)" name="gender" genderOptions={genderOptions} value={gender} disabled={nameDisabled} onChange={(e) => setGender(e.target.value)} />
                            </>}
                        <InputComponent label="Número RG ou CNH" type="tel" placeholder="00000000" value={rg} onChange={(e) => setRg(e.target.value)} autoDisabled={defaultDisabled} />
                        <InputComponent label="Órgão expedidor" type="text" placeholder="Digite aqui..." value={issuingAgency} onChange={(e) => setIssuingAgency(e.target.value)} autoDisabled={defaultDisabled} />
                        <InputMaskComponent id="issueDate" label="Data da expedição do documento" mask="99/99/9999" type="tel" placeholder="DD/MM/AAAA" value={issueDate} onChange={(e) => handleDates(e, setIssueDate, 'issueDate')} autoDisabled={defaultDisabled} />
                        {/* <SelectComponent label="Estado de nascimento (UF)" stateOptions={estadosCidades} value={birthState} onChange={(e) => setBirthState(e.target.value)} disabled={defaultDisabled} />
            <SelectComponent label="Sua cidade de nascimento" cityOptions={cityOptions} value={birthCity} onChange={(e) => setBirthCity(e.target.value)} disabled={defaultDisabled} /> */}
                        <div className="containerTitle">
                            <strong>Seu endereço</strong>
                            <div />
                        </div>
                        {/* <InputComponent label="Seu melhor e-mail" type="email" placeholder="exemplo@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            <InputMaskComponent mask="(99) 99999-9999" label="Seu WhatsApp" type='tel' placeholder="(DDD)" value={cellphone} onChange={(e) => setCellphone(e.target.value)} icon={<img src={whatsAppIcon} style={{
              width: 65,
              marginLeft: -20,
              marginBottom: -2
            }} />} /> */}
                        {!postalCode &&
                            <InputMaskComponent mask="99.999-999" label="Seu atual CEP" type='tel' placeholder="00.000-000" value={CEP} onChange={(e) => {
                                setCEP(e.target.value)
                                if (formatinputs.toString(e.target.value).length === 8) {
                                    getCep(e.target.value)
                                }
                            }} />}
                        <SelectComponent label="Seu atual estado" stateOptions={estadosCidades} disabled={adressInputsDisabled} value={state} onChange={(e) => setState(e.target.value)} />
                        <SelectComponent label="Sua atual cidade" cityOptions={cityOptions} disabled={adressInputsDisabled} value={city} onChange={(e) => setCity(e.target.value)} />
                        <InputComponent haveNumber label="Seu atual endereço" type="text" placeholder="Digite aqui..." value={address} onChange={(e) => setAddress(e.target.value)} />
                        <InputComponent label="Número" type="tel" placeholder="Digite aqui..." value={addressNumber} onChange={(e) => setAddressNumber(e.target.value)} />
                        <InputComponent haveNumber label="Complemento (opcional)" type="text" placeholder="Digite aqui..." value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} />
                        <InputComponent haveNumber label="Seu atual bairro" type="text" placeholder="Digite aqui..." value={district} onChange={(e) => setDistrict(e.target.value)} />
                        <p className="textRecaptcha">Clique na caixa reCAPTCHA para prosseguir</p>
                        <ReCAPTCHA
                            className="recaptcha"
                            sitekey='6Ld5lJ0iAAAAAD6OmWWHph9YnYy16zzqLhPyf-1r'
                            // sitekey='6Lfe-vIiAAAAAPJnByZGEr4IN7k0DF5fdXMVDAmM'
                            onChange={(e: any) => {
                                setRecaptchaToken(e)
                            }}

                        />
                        <button className="buttonContinue" onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            })
                            nextRegisterSteps()

                        }}>Continuar</button>
                        <div className="info">
                            <p><strong>"Seus dados pessoais estão sob sigilo</strong> e serão tratados pela empresa <strong>Assertiva,</strong> em
                                conformidade com a lei 13.709/2018, Lei Geral de Proteção de Dados - LGPD"</p>
                        </div>
                        <Tarja />
                    </>
                )
            case 3:
                return (
                    <>
                        <StepIndicator />
                        <div className="containerTitle">
                            <strong style={{ color: 'red', fontSize: '16px' }}>{name},</strong>
                            <strong style={{ fontSize: '18px' }}>Preencha com suas informações bancárias vinculadas ao CPF: {formatinputs.toCPF(CPF)}</strong>
                            <div className="bar" />
                            <div className="infoCard">
                                <p>É através desta conta bancária que receberá o valor solicitado</p>
                            </div>
                        </div>
                        <SelectComponent label="Tipo de conta" genderOptions={accountTypes} value={accountType} onChange={(e) => setAccountType(e.target.value)} />
                        <SelectComponent label="Banco" bankOptions={banks.banks} value={bank} onChange={(e) => setBank(e.target.value)} />
                        <InputComponent label="Agência" type="tel" placeholder="Digite aqui..." value={agency} onChange={(e) => setAgency(e.target.value)} />
                        <InputComponent label="Conta e Dígito" type="tel" placeholder="Digite aqui..." value={formatAccount(account)} onChange={(e) => setAccount(e.target.value.replaceAll('-', ''))} />
                        <button className="buttonContinue" onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            })
                            nextRegisterSteps()

                        }}>Continuar</button>
                        <Tarja />

                    </>
                )

            case 4:
                return (
                    <div className="contentSMS">
                        <div className="awaitSMS">
                            <p>
                                Para confirmação, <strong>enviamos um <u>SMS</u> e <u>E-MAIL</u>.</strong>
                            </p>
                            <p>
                                Confirme agora o SMS digitando <strong>1</strong> ou
                                confira seu E-MAIL para confirmação.
                            </p>
                            <p>
                                Após confirmar seu SMS ou E-MAIL,
                            </p>

                            <p>Retorne imediatamente para seu navegador e finalize seu empréstimo</p>
                            <img src={chatImage} alt="chatImage" />
                        </div>
                    </div>
                )

            case 5:
                return (
                    <div className="ContainerDontHaveCard">
                        <Grid item xs={12} sx={{
                            backgroundColor: '#F5B000',
                            minWidth: '100%',
                            height: '151px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            m: '0px 0px 30px 0px',
                        }}>
                            <ErrorOutline sx={{
                                fill: 'white !important',
                                width: '60px',
                                height: '60px'
                            }} color="error" />
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: '0px 80px',
                            textAlign: 'center',
                            gap: '5px'
                        }}>
                            <Typography variant="h2" sx={{ fontWeight: 'bold', mb: '10px' }}>INFORMATIVO</Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: '10px' }}>SISTEMA DE ANÁLISE CADASTRAL CLEARSALE</Typography>
                            <Typography style={{ fontSize: '2rem', color: 'red' }}>Não foi possível darmos continuidade em sua proposta devido a inconsistências cadastrais</Typography>
                            <Typography style={{ fontSize: '2rem' }}>Os dados cadastrais inseridos não foram aprovados na validação cadastral clearsale</Typography>

                            <Typography sx={{ fontWeight: 'bold', mt: '10px', fontSize: '2rem', textDecoration: 'underline' }}>Prováveis motivos para recusa:</Typography>
                            <ul style={{ textAlign: 'left' }}>
                                <li style={{
                                    padding: '0px 10px'
                                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Número de telefone recente ou cadastrado em outro CPF;</Typography></li>
                                <li style={{
                                    padding: '0px 10px'
                                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Email criado recentemente ou sem atividades recentes;
                                    </Typography></li>
                                <li style={{
                                    padding: '0px 10px'
                                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Endereço errado ou mudou-se recentemente;
                                    </Typography></li>
                                <li style={{
                                    padding: '0px 10px'
                                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Documentos emitidos ou extraviados recentemente;
                                    </Typography></li>
                                <li style={{
                                    padding: '0px 10px'
                                }}><Typography style={{ fontSize: '1.8rem', textAlign: 'start' }}>Email, telefone ou CPF envolvidos em fraudes ou em outras ocorrências</Typography></li>
                            </ul>
                            <Typography style={{ fontSize: '1.5rem', color: 'gray' }}>Sugerimos que identifique acima a inconsistência cadastral, atualize os dados e após 24 horas envie uma nova proposta.</Typography>
                        </Grid>
                        <div className="containerButtons">
                            <button onClick={() => {
                                window.open("https://ayude.com.br", "_self")
                            }} className="exitButton">Entendi</button>
                        </div>

                    </div>
                )
            case 6:
                return (
                    <>
                        <StepIndicator />
                        <p className="title">Solicitamos que <strong>digite seus dados pessoais</strong> corretamente.</p>
                        <div className="info">
                            <p><strong>"Seus dados pessoais estão sob sigilo</strong> e serão tratados pela empresa <strong>Assertiva,</strong> em
                                conformidade com a lei 13.709/2018, Lei Geral de Proteção de Dados - LGPD"</p>
                        </div>
                        <div className="containerTitle">
                            <strong>Informações cadastrais</strong>
                            <div />
                        </div>
                        <SelectComponent label="Gênero (Conforme Receita Federal)" name="gender" genderOptions={genderOptions} value={gender} disabled={nameDisabled} onChange={(e) => setGender(e.target.value)} />
                        <InputComponent label="Número RG ou CNH" type="tel" placeholder="00000000" value={rg} onChange={(e) => setRg(e.target.value)} autoDisabled={defaultDisabled} />
                        <InputComponent label="Órgão expedidor" type="text" placeholder="Digite aqui..." value={issuingAgency} onChange={(e) => setIssuingAgency(e.target.value)} autoDisabled={defaultDisabled} />
                        <InputMaskComponent id="issueDate" label="Data da expedição" mask="99/99/9999" type="tel" placeholder="DD/MM/AAAA" value={issueDate} onChange={(e) => handleDates(e, setIssueDate, 'issueDate')} autoDisabled={defaultDisabled} />
                        <button className="buttonContinue" onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            })
                            nextRegisterSteps()

                        }}>Continuar</button>
                        <Tarja />
                    </>
                )
        }
    }

    return (
        <div className="container">
            <Dialog open={openFormError} className="DialogRegister">
                <p>Por favor siga as instruções abaixo para prosseguir:</p>
                <ul>
                    {errorList.map((item) => {
                        return <li key={item.path}>{item.message}</li>
                    })}
                </ul>
                <button onClick={() => setOpenFormError(false)}>Entendi</button>
            </Dialog>

            <Dialog open={openFormErrorTransfeera} className="DialogRegister">
                <p>Por favor siga as instruções abaixo para prosseguir:</p>
                <ul>
                    <li>Agência, Conta ou dígito verificador inválido.</li>
                </ul>
                <p style={{ padding: '10px 10px', fontSize: '12px' }}>Obs: Informe a agência SEM DÍGITO.</p>
                <button onClick={() => {
                    setOpenFormErrorTransfeera(false)
                    setLoading(false)
                }}>Entendi</button>
            </Dialog>

            <Dialog open={openDialogAccount} className="DialogAccount">
                <p>Dados bancários <u>inválidos</u></p>
                <p>Por favor informe seus dados bancários corretos</p>
                <ul>
                    <li>Tipo de conta</li>
                    <li>Banco</li>
                    <li>Agência</li>
                    <li>Conta bancária</li>

                </ul>
                <p>Obs: A conta bancária deverá ter como titular o CPF: <u><strong>{CPF}</strong></u></p>
                <button onClick={() => setOpenDialogAccount(false)}>Entendi</button>
            </Dialog>

            <Dialog open={openSintegraAlert}>
                <p>Estamos impossibilitados de darmos continuidade em seu cadastro.
                    <br />
                    Devido a inconsistência nos dados digitados.</p>
                <button className="buttonSintegra" onClick={() => setOpenSintegraAlert(false)}>Digitar corretamente</button>
            </Dialog>

            <Dialog open={dialogTransfeeraAproved} className="dialogAlert">
                <>
                    <p style={{ fontSize: '20px' }}>VALIDAÇÃO DA CONTA BANCÁRIA</p>

                    <p>Para isso enviaremos para a conta bancária informada um micro depósito,
                        transferência no valor de R$ <u style={{ fontSize: '15px' }}> <strong>0,01</strong> </u> em nome de
                        <u> NACIONAL PAGAMENTOS.</u>
                    </p>

                    <p><u>Não é necessário confirmar</u> o recebimento do valor, a verificação é automática.</p>
                    <button
                        className="confirmButton"
                        onClick={() => {
                            setDialogTransfeeraAproved(false)
                            setProposalToken(reservedToken)
                            setCheckoutSteps(4)
                            setStep(3)
                            setProgressIndicator(progressIndicator + 1)
                        }}>Entendi</button>
                </>
            </Dialog>

            <Dialog open={openModalContactReason} style={{
                fontFamily: 'Poppins !important'
            }}>
                <p style={{ fontSize: '2.5rem', padding: '5px', marginTop: '0px', fontWeight: 'bold', margin: '15px 0px' }}>Para continuar, <br /> informe abaixo o motivo da contratação</p>
                <div style={{
                    width: '90%'
                }}>
                    <SelectComponent
                        genderOptions={[
                            {
                                label: "Despesas básicas",
                                value: "0"
                            },
                            {
                                label: "Emergências",
                                value: "1"
                            },
                            {
                                label: "Educação",
                                value: "2"
                            },
                            {
                                label: "Consolidação de dívidas",
                                value: "3"
                            },
                            {
                                label: "Reformas",
                                value: "4"
                            },
                            {
                                label: "Lazer",
                                value: "5"
                            },
                            {
                                label: "Invetimento pessoal",
                                value: "6"
                            },
                            {
                                label: "Potencializar negócio",
                                value: "7"
                            },
                            {
                                label: "Outros",
                                value: "8"
                            },
                        ]}
                        label="Motivo da contratação"
                        value={contactReason}
                        onChange={(e) => setContactReason(e.target.value)}
                        isDialog
                    />
                </div>

                <button
                    style={{
                        marginTop: '10px',
                        padding: '10px',
                        maxWidth: '40rem',
                        width: '40rem',
                        height: 'auto',
                        fontSize: '2.5rem'
                    }}
                    onClick={handleContactReason}
                >Continuar</button>
            </Dialog>

            <Dialog open={openModalConfirmation} style={{
                fontFamily: 'Poppins !important'
            }}>
                <p style={{ fontSize: '3.5rem', padding: '5px', fontWeight: 'bold', fontFamily: 'Poppins !important' }}>Enviamos uma mensagem para você!</p>
                <p style={{ fontSize: '2.5rem', padding: '5px', marginTop: '0px', marginBottom: '5px' }}>Para continuar, <br /> acesse o whatsapp</p>
                <ul>
                    <li style={{ fontSize: '2.5rem', padding: '5px', textAlign: 'left', }}><strong>Confirme seus dados;</strong></li>
                    <li style={{ fontSize: '2.5rem', padding: '5px', textAlign: 'left', }}><strong>Salve nosso número em seu telefone</strong> e</li>
                    <li style={{ fontSize: '2.5rem', padding: '5px', textAlign: 'left', marginBottom: '10px', fontWeight: 'bold' }}>Clique no link de empréstimo</li>
                </ul>

                <button
                    style={{
                        padding: '10px',
                        maxWidth: '40rem',
                        width: '40rem',
                        height: 'auto',
                        fontSize: '2.5rem'
                    }}
                    onClick={() => {
                        window.open(`https://api.whatsapp.com/send?phone=554187227846`, '_blank')
                    }}
                >Clique para acessar seu <br /> Whatsapp</button>
            </Dialog>
            {render()}
        </div>
    )
}

