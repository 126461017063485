import { styled } from "@mui/material/styles";
import { Grid, MobileStepper } from "@mui/material";

export const GridContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
  height: '100vh',
  maxWidth: '100vw',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative'
}))

export const Stepper = styled(MobileStepper)(({ theme, id }) => ({
  ...(id === 'false' ? {
    color: theme.palette.custom?.error
  }: {
    color: theme.palette.primary.main,
  }),
  flex: 1,
}))